import { getUrl } from 'helpers/url';

import type { HelmetLinkProp } from './types';

interface BuildLinksProps {
  currentPath: string;
  siteUrl: string;
  prevPath?: string;
  nextPath?: string;
}

const getNavLinks = ({ prevPath, nextPath }: { prevPath?: string; nextPath?: string }) => {
  const links: { rel: string; href: string }[] = [];

  if (prevPath) links.push({ rel: 'prev', href: prevPath });
  if (nextPath) links.push({ rel: 'next', href: nextPath });

  return links;
};

export function buildLinks({
  currentPath,
  siteUrl,
  prevPath,
  nextPath,
}: BuildLinksProps): HelmetLinkProp {
  return [
    {
      rel: 'alternate',
      href: getUrl({ path: '/blog/feed.xml', siteUrl }),
      type: 'application/rss+xml',
      title: 'Sharesight RSS Feed',
    },
    { rel: 'canonical', href: getUrl({ path: currentPath, siteUrl }) },
  ].concat(getNavLinks({ prevPath, nextPath }));
}
