import styled from 'styled-components';

import { bodyPaddingMixin } from 'styled/helpers/bodyPaddingMixin';
import { getDimension } from 'styled/helpers/getDimension';

export const Nav = styled.nav`
  ${bodyPaddingMixin}

  height: ${props => props.theme.components.header.height};
  align-items: center;

  display: grid;
  grid-template-columns: 1fr min-content min-content min-content;
  grid-gap: ${getDimension('size4')};

  /* ⚠️ Disable padding-top + bottom after bodyPaddingMixin has set them! */
  padding-top: 0;
  padding-bottom: 0;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: min-content 1fr min-content min-content;
    grid-gap: ${getDimension('size1')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: min-content 1fr min-content;
    grid-gap: ${getDimension('size1')};
  }
`;
