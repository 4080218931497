import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { mergeTranslations } from 'helpers/mergeTranslations';
import { matchesWildcard } from 'helpers/matchesWildcard';
import { getComparablePath } from 'helpers/getComparablePath';
import { findNodePages } from 'hooks/useContentfulPage/findNodePages';
import { useSocialImage } from 'hooks/useContentfulPage/useSocialImage';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { ContentfulPage, ContentfulPagesGraphQLResponse } from 'hooks/useContentfulPage';

const query = graphql`
  query useGatsbyPageQuery {
    allContentfulPage {
      nodes {
        contentfulId: contentful_id
        localeLang: node_locale
        path
        title
        description
        noIndex
        shouldAppendTitle
        translations {
          internal {
            content
            mediaType
          }
        }
        cards {
          contentfulId: contentful_id
          title
          cardIdentifier
          richText {
            raw
          }
          image {
            file {
              url
              fileName
              contentType
            }
            gatsbyImageData
            title
          }
        }
        accordionItems {
          question
          answer {
            raw
          }
        }
        sections {
          header
          textContent {
            raw
          }
          image {
            file {
              url
              fileName
              contentType
            }
            gatsbyImageData
            title
          }
        }
        socialImage {
          file {
            url
            contentType
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;

const hasPaginatedPages = (path: string) => {
  const paginatedPages = ['blog', 'partners'];

  return paginatedPages.some(page => path.startsWith(page) || path.startsWith(`/${page}`));
};

export const useGatsbyPage = ({
  path,
  locale,
}: {
  path: string;
  locale: Locale;
}): ContentfulPage | undefined => {
  const { appendTitle, socialAppendTitle } = useContentfulLocale(locale);
  const { defaultLocale } = useLocalizationContext();
  const { allContentfulPage }: ContentfulPagesGraphQLResponse = useStaticQuery(query);

  const { localePage, globalPage, contentfulId } = useMemo(() => {
    const comparePath = getComparablePath(path);

    /*
     * NOTE: We use globs to match the `baseUrl` against pages in Contentful.
     * However, there appears to be no glob library that works in a browser 🤬…so we built our own `matchesWildcard`..
     */
    const comparePaths = [comparePath, path];
    const currentPageNodes = allContentfulPage.nodes.filter(node =>
      comparePaths.some(p => {
        if (matchesWildcard(p, node.path)) return true;
        if (hasPaginatedPages(p)) {
          return matchesWildcard(p, `${node.path}/pages/*`);
        }
        return false;
      })
    );

    return findNodePages({
      nodes: currentPageNodes,
      defaultLocale,
      locale,
      debug: `Contentful::Gatsby for path='${path}'`,
    });
  }, [allContentfulPage.nodes, defaultLocale, locale, path]);

  const socialImage = useSocialImage({
    fixed: {
      src: globalPage?.socialImage?.file.url,
      width: globalPage?.socialImage?.file.details.image.width,
      height: globalPage?.socialImage?.file.details.image.height,
    },
    file: { contentType: globalPage?.socialImage?.file.contentType },
  });

  if (!localePage || !globalPage) return undefined;

  let title = localePage.title;
  if (localePage.shouldAppendTitle) title += ` | ${appendTitle}`;

  let socialTitle = globalPage.title;
  if (globalPage.shouldAppendTitle) socialTitle += ` | ${socialAppendTitle}`;

  return {
    cards: localePage.cards || globalPage.cards,
    accordionItems: localePage.accordionItems || globalPage.accordionItems,
    sections: localePage.sections || globalPage.sections,

    seoProps: {
      noIndex: localePage.noIndex,
      title,
      rawTitle: localePage.title, // passed around so we can use it for some labels or alt/title attributes
      description: localePage.description,
      socialTitle,
      socialImage,
      socialDescription: globalPage.description,
    },

    // NOTE: Throws an error if translations are invalid..
    translations: mergeTranslations(
      globalPage.translations,
      localePage.translations,
      `ContentfulPage::Gatsby::${contentfulId}`
    ),
  };
};
