import { HelpLink } from 'components/Links/HelpLink';
import { CommunityLink } from 'components/Links/CommunityLink';
import { AppLink } from 'components/Links/AppLink';

import { GatsbyLink } from 'components/Links';
import { Link as LinkStyled, LinkHighlight, StyledCaretRight } from './Link.styled';

import type { FC } from 'react';
import type { MenuLink } from 'hooks/useNavigationLinks/types';

interface MenuLinkProps {
  link: MenuLink;
}

export const Link: FC<MenuLinkProps> = ({ link }) => {
  const to = (link.path || link.helpPath || link.appPath || link.communityPath) as string;

  let as: typeof GatsbyLink | typeof HelpLink | typeof AppLink | typeof CommunityLink = GatsbyLink;
  if (link.helpPath) as = HelpLink;
  if (link.appPath) as = AppLink;
  if (link.communityPath) as = CommunityLink;

  return (
    <LinkStyled as={as} to={to} title={link.title}>
      <LinkHighlight>
        {link.label}

        <StyledCaretRight weight="bold" />
      </LinkHighlight>
    </LinkStyled>
  );
};
