import { unLocalizedPaths as defaultUnLocalizedPaths } from 'data/unLocalizedPaths';

import { getLocalizedPath } from './getLocalizedPath';
import { getUrl } from './getUrl';
import { normalizePath } from './normalizePath';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { UnLocalizedPath } from 'data/unLocalizedPaths';

interface GetLocalizedUrlOptions {
  locale: Locale;
  siteUrl: string;
  path: string;
  unLocalizedPaths?: UnLocalizedPath[];
}

export function getLocalizedUrl({
  locale,
  siteUrl,
  path,
  unLocalizedPaths = defaultUnLocalizedPaths,
}: GetLocalizedUrlOptions): string {
  return getUrl({
    siteUrl,
    path: getLocalizedPath({ locale, path: normalizePath({ path, siteUrl }), unLocalizedPaths }),
  });
}
