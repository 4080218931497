import { useCallback, useState } from 'react';
import { List } from 'phosphor-react';

import { Overlay } from './Overlay';
import { MenuContainer } from './MenuContainer.styled';
import { MenuButton } from './MenuButton.styled';

export const MobileNavigation = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleOpen = useCallback(() => {
    setShowOverlay(true);
  }, [setShowOverlay]);

  const handleClose = useCallback(() => {
    setShowOverlay(false);
  }, [setShowOverlay]);

  return (
    <MenuContainer>
      <MenuButton onClick={handleOpen} appearance="tertiary" aria-label="Open Mobile Navigation">
        <List size="36" />
      </MenuButton>

      {showOverlay && <Overlay handleClose={handleClose} />}
    </MenuContainer>
  );
};
