import { css } from 'styled-components';
import { gridOrFlexLayoutMixin } from './gridOrFlexLayoutMixin';

import type { GridOrFlexLayoutMixinProps } from './gridOrFlexLayoutMixin';

type FlexLayoutMixinProps = GridOrFlexLayoutMixinProps;

export const flexLayoutMixin = ({
  scaleMobileGap = true,
  manageLayout = true,
  verticalAlign = 'center',
  gap = 10,
  theme,
}: FlexLayoutMixinProps) => {
  if (manageLayout !== true) return undefined;

  return css`
    ${gridOrFlexLayoutMixin({ scaleMobileGap, manageLayout, verticalAlign, gap, theme })}

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  `;
};
