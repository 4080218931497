import { useStaticQuery, graphql } from 'gatsby';
import { getParsedSimpleBlogPost } from 'helpers/getParsedBlogPost';

import type { SimpleBlogPost, SimpleBlogPostsGraphQLResponse } from 'types/blog/simpleBlogPost';

/**
 * NOTE: If you want to add additional `featuredImage` sizes, generate new ones with aliases, eg.:
 *  - This will alias it as `featuredImage.sm` instead: `sm: fixed(width: 750, quality: 95) { src }`
 */
const BlogPostsQuery = graphql`
  query ContentfulMenuBlogs {
    allContentfulPost {
      nodes {
        id
        contentfulId: contentful_id
        title {
          title
        }
        urlSlug
        featuredImage {
          title
          fixed(width: 750, quality: 95) {
            src
          }
        }
      }
    }
  }
`;

export function useContentfulBlogPost(urlSlug: string): SimpleBlogPost {
  const { allContentfulPost } = useStaticQuery<SimpleBlogPostsGraphQLResponse>(BlogPostsQuery);

  const post = allContentfulPost.nodes.find(node => node.urlSlug === urlSlug);

  if (!post) {
    throw new Error(`Could not find a Contentful Blog Post for urlSlug='${urlSlug}'.`);
  }

  return getParsedSimpleBlogPost(post);
}
