import { datadogRum } from '@datadog/browser-rum';
import { Suspense, lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

import { useIntercom } from 'react-use-intercom';
import { Header } from 'components/Header';
import { CampaignCookie } from 'components/CampaignCookie';
import { GlobalStyles } from 'styled/GlobalStyles';
import { initializeGoogleTagManager } from 'helpers/initializeGoogleTagManager';

import { UTMCookie } from 'components/UTMCookie';
import type { FC, ReactNode } from 'react';

const Footer = lazy(() => import('components/Footer'));

export interface LayoutProps {
  children: ReactNode;
  /** Used in very rare scenarios, e.g. avoiding two consecutive cta's on mobile in the exec team page */
  hideFooterCta?: boolean;
}

export const Layout: FC<LayoutProps> = ({ children, hideFooterCta = false }) => {
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    // TODO: In future this should change to relying on cookie settings
    const allowReportingCookies = true;
    const allowAdvertisingCookies = true;

    if (allowAdvertisingCookies) {
      initializeGoogleTagManager();
    }

    if (allowReportingCookies) {
      ReactGA.initialize('G-RW4RQLNJDN', {
        gtagOptions: {
          // Page views are also tracked within GTM
          send_page_view: true,
        },
      });
    }

    datadogRum.init({
      applicationId: '05b2c304-e24f-416d-9c84-5c0d3403e395',
      clientToken: 'pubd1ee8f5b1edcf666bc666980a2903110',
      site: 'datadoghq.com',
      service: 'www',
      env: process.env.NODE_ENV || 'production',
      /*
       * Specify a version number to identify the deployed version of your application in Datadog
       * version: '1.0.0',
       */
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();

    if (
      window.location.pathname.includes('/partners/') ||
      window.location.pathname.includes('/supported-stock-exchanges-managed-funds-mutual-funds/')
    ) {
      boot();
    } else {
      shutdown(); // or shutdown();
    }
  }, [boot, shutdown]);

  return (
    <>
      <GlobalStyles />

      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
      </Helmet>
      {/* Sets a cookie for tracking affiliates and campaigns across sharesight.com sites. */}
      <CampaignCookie />
      {/* Sets a cookie for UTM parameters across sharesight.com sites. */}
      <UTMCookie />

      <Header />
      <main>{children}</main>

      <Suspense fallback={<div />}>
        <Footer hideCta={hideFooterCta} />
      </Suspense>
    </>
  );
};
