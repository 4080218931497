import { theme as defaultTheme } from 'styled/theme';

import type { Dimension, Theme } from 'styled/types';

interface GetDimensonParams {
  theme?: Theme;
}

export const getDimension =
  (size: Dimension) =>
  ({ theme }: GetDimensonParams) => {
    if (!size) return '0px';

    const { dimensions } = theme || defaultTheme;
    const sizeValue = dimensions[size];

    if (sizeValue === undefined) {
      throw new Error(`Could not find size value for ${size}`);
    }

    return `${sizeValue}px`;
  };
