import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { isProPath } from 'helpers/url';
import { AppLink } from './AppLink';

import type { FC } from 'react';
import type { AppLinkProps } from './AppLink';

interface LoginLinkProps extends Omit<AppLinkProps, 'onClick' | 'to' | 'openInNewTab'> {
  to?: string;
}

export const LoginLink: FC<LoginLinkProps> = ({
  children = 'Log in',
  title = 'Log in to Sharesight',
  appearance = 'secondary',
  color,
  textColor,
  borderColor = 'black',
  asButton = false,
  ...props
}) => {
  const { basePath } = useLocalizationContext();

  let to = '/login';
  if (isProPath({ basePath })) to = '/users/professional_login';

  return (
    <AppLink
      openInNewTab={false}
      {...props}
      color={color}
      textColor={textColor}
      appearance={appearance}
      to={to}
      title={title}
      borderColor={borderColor}
      asButton={asButton}
    >
      {children}
    </AppLink>
  );
};
