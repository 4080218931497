import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: none;
  text-align: left;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: flex;
  }
`;
