import { useStaticQuery, graphql } from 'gatsby';

import type { SiteMetadataGraphQL, SiteMetadata } from './types';

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        siteUrl
        helpUrl
        appUrl
        communityUrl
      }
    }
  }
`;

export const useSiteMetadata = (): SiteMetadata => {
  const { site }: SiteMetadataGraphQL = useStaticQuery(query);

  return {
    siteUrl: site.siteMetadata.siteUrl,
    helpUrl: site.siteMetadata.helpUrl,
    appUrl: site.siteMetadata.appUrl,
    communityUrl: site.siteMetadata.communityUrl,
  };
};
