import { BaseButton } from './BaseButton';
import type { HrefButtonProps } from './types';

export const HrefButton = ({
  children,
  'aria-label': ariaLabel,
  href,
  rel = undefined,
  target = '_self',
  title,
  openInNewTab,
  ...props
}: HrefButtonProps) => (
  <BaseButton
    {...props}
    as="a"
    href={href}
    rel={openInNewTab ? 'noopener noreferrer' : rel}
    target={openInNewTab ? '_blank' : target}
    title={title}
    aria-label={ariaLabel}
    type={undefined}
    role="link"
  >
    {children}
  </BaseButton>
);
