import styled from 'styled-components';
import { X } from 'phosphor-react';

import { Button } from 'components/Button';
import { Underline } from 'components/Underline.styled';

import { getDimension } from 'styled/helpers/getDimension';
import type { FC } from 'react';
import type { OverlayProps } from './Overlay';

const StyledButton = styled(Button)`
  position: absolute;
  top: -${getDimension('size10')};
  right: -${getDimension('size10')};
  z-index: 100;

  ${({ theme }) => theme.breakpoints.down('md')} {
    top: -${getDimension('size4')};
    right: -${getDimension('size4')};
  }
`;

interface CloseButtonProps {
  handleClose: OverlayProps['handleClose'];
}

export const CloseButton: FC<CloseButtonProps> = ({ handleClose }) => (
  <StyledButton color="transparent" noUnderline onClick={handleClose}>
    <Underline>Close</Underline> &nbsp;
    <X weight="bold" />
  </StyledButton>
);
