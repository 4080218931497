import { getUrl } from 'helpers/url';

import { theme } from 'styled/theme';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { HelmetMetaProp, MetaProps } from './types';

interface BuildMetaTagsContext {
  basePath: string;
  siteUrl: string;
  currentLocale: Locale;
  availableLocales: Locale[];
}

export function buildMetaTags(
  {
    noIndex,
    title,
    socialTitle = title,
    description,
    socialDescription = description,
    socialImage,
  }: MetaProps,
  { currentLocale, availableLocales, basePath, siteUrl }: BuildMetaTagsContext
): HelmetMetaProp {
  return [
    { name: 'robots', content: noIndex ? 'NoIndex' : 'Index, Follow' },
    { name: 'msvalidate.01', content: 'D71EF5876D9C5F2034E74AC314553CA3' },
    { name: 'facebook-domain-verification', content: 'faljgfs5wx7lvxx4lwlc3516vohui3' },
    { name: 'theme-color', content: theme.colors.primary },
    { name: 'application-name', content: 'Sharesight' },
    { name: 'description', content: description },
    { name: 'referrer', content: 'strict-origin' },
    { property: 'fb:app_id', content: '1028405463915894' },
    { property: 'og:site_name', content: 'Sharesight' },
    { property: 'og:title', content: socialTitle },
    { property: 'og:description', content: socialDescription },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: getUrl({ path: basePath, siteUrl }) },
    { property: 'og:locale', content: currentLocale.lang.replace('-', '_') }, // og:locale wants` en_US`, not `en-US`
    { property: 'og:image', content: socialImage.src },
    { property: 'og:image:width', content: String(socialImage.width) },
    { property: 'og:image:height', content: String(socialImage.height) },
    { property: 'og:image:type', content: socialImage.type },
    ...availableLocales
      .filter(locale => locale.lang !== currentLocale.lang)
      .map(locale => ({
        property: 'og:locale:alternate',
        content: locale.lang.replace('-', '_'), // og:locale wants` en_US`, not `en-US`
      })),
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: socialImage.src },
    { name: 'twitter:site', content: '@sharesight' },
    { name: 'twitter:site:id', content: '109123696' },
  ];
}
