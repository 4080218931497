import { normalizePath } from './normalizePath';
/** These paths are assumed pro pages, eg. `/pro` or `/financial-advisers` */
const proPaths = ['professionals', 'accountants', 'financial-advisers'];

/** Determine whether or not the current page is a pro path, eg. `/pro` is considered a pro path which changes some logic on the page, like a signup link. */
export const isProPath = ({ basePath }: { basePath: string }) => {
  const currentPath = normalizePath({ path: basePath });

  return proPaths.some(path => normalizePath({ path }) === currentPath);
};
