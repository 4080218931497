import { getBlogUrlSlug } from 'helpers/url';

import type { BuildBlogSchemaContext, SchemaJsonObject } from './types';

export const buildBlogSchema = ({
  siteUrl,
  basePath,
  blogPost,
}: BuildBlogSchemaContext): SchemaJsonObject => {
  const id = `${siteUrl}${basePath}`;
  const slug = getBlogUrlSlug(basePath);

  const schemaObject = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': id,
    },
    headline: blogPost.title,
    description: blogPost.description,
    image: blogPost.image.src,
    author: {
      '@type': 'Person',
      name: blogPost.author,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Sharesight',
      logo: {
        '@type': 'ImageObject',
        url: 'https://dhln4w2iqv5o4.cloudfront.net/assets/logo-colour-default-b07a14e9f91e896b747ebc234cfc964bbcc2dfe66785c080485dd1d532c82b6f.svg',
      },
    },
    datePublished: blogPost.date,
  };

  return { key: `${slug}-blogPosting`, json: JSON.stringify(schemaObject) };
};
