import { unLocalizedPaths as defaultUnLocalizedPaths } from 'data/unLocalizedPaths';

import { normalizePath } from './normalizePath';
import { shouldLocalizePath } from './shouldLocalizePath';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { UnLocalizedPath } from 'data/unLocalizedPaths';

interface GetLocalizedPathOptions {
  locale: Locale;
  path: string;
  unLocalizedPaths?: UnLocalizedPath[];
}

export function getLocalizedPath({
  locale,
  path,
  unLocalizedPaths = defaultUnLocalizedPaths,
}: GetLocalizedPathOptions) {
  // Combine the current locale path (may be empty) and the current path.
  let localizedPath = path;
  if (shouldLocalizePath({ path, unLocalizedPaths })) {
    localizedPath = [locale.path, path].filter(a => a).join('/');
  }

  // Wrap in slashes, remove duplicate slashes, handle anchor/params, etc.
  localizedPath = normalizePath({ path: localizedPath });

  return localizedPath;
}
