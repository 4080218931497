import styled from 'styled-components';

import { Container } from 'components/Container';

export const HeaderContainer = styled(Container).attrs(() => ({
  containerAs: 'header', // a <header> instead of <section>
  padding: 0, // NOTE: Padding has been moved elsewhere for Dropdown alignment
  manageLayout: false,
  background: 'white',
}))`
  position: sticky;
  top: 0;
  z-index: 1000;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
