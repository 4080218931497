/** WARNING: Ensure we don't allow something like `hacksharesight.com` when we're trying to allow a wildcard subdomain. */
export const domainWhitelist: (string | RegExp)[] = [
  /**
   * Sharesight subdomains.
   * `www.sharesight.com` and `sharesight.com` domains are treated differently (external to this validation)
   * NOTE: According to this, `sharesight.com` is not a whitelisted domain as we don't want users linking to it, we'd prefer `/faq` instead of `sharesight.com/faq`.
   */
  /^.*\.sharesight\.com$/,
  /^.*\.investopedia\.com$/,
  /irs\.gov$/,
  /ird\.govt.nz$/,
  /gov\.uk$/,
  /ato\.gov\.au$/,
  /canada\.ca$/,
  /vanguardcanada\.ca$/,
  /^.*\.youtube\.com$/,
  /budget\.gov\.au$/,
  /form\.asana.com$/,
  /^.*\.ifa\.com.au$/,
  /^.*\.buzzsprout\.com$/,
  /pca\.st$/,
  /legalvision\.com\.au$/,
  /^.*\.linkedin\.com$/,
  /confirmsubscription\.com$/,
  /docs\.google\.com$/,
  /forms\.gle$/,
  /us02web\.zoom\.us$/,
];

/** Validate if a domain is in our whitelist and using the `https` protocol.  We throw an error if not. */
export const validateDomain = (link: string, additionalErrorMessage: string): true | void => {
  const url = new URL(link);

  if (url.protocol !== 'https:') {
    throw new Error(
      `Contentful Rich Text external link is insecure (using '${url.protocol}'). ${additionalErrorMessage}`
    );
  }

  const isValid = domainWhitelist.some(domain => {
    /** `'sharesight.com' === 'sharesight.com'` */
    if (typeof domain === 'string') return domain === url.hostname;
    return domain.test(url.hostname);
  });

  if (!isValid) {
    throw new Error(
      `Contentful Rich Text external link to hostname='${url.hostname}' is not supported. ${additionalErrorMessage}`
    );
  }

  return true;
};

/** This is to validate that a link and a url share the same origin.  We catch around if `link` is not a valid url, we return false explicitly. */
export const isUrlAndSameOrigin = (link: string, url: string): boolean => {
  try {
    const linkUrl = new URL(link);
    const parsedUrl = new URL(url);

    /** This compares `https://help.sharesight.com` === `https://help.sharesight.com` eg. on `to = 'https://help.sharesight.com/page'` */
    return linkUrl.origin === parsedUrl.origin;
  } catch (e) {
    // NOTE: In the case that `to` or `url` are not valid urls, eg. `/pricing`, we just return false.
    return false;
  }
};
