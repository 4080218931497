const WILDCARD = '*';

function regexFromGlob(glob: string): RegExp {
  return new RegExp(glob.replace(/\*/g, '.*'));
}

/**
 * WARNING: This is not really a glob matcher.
 * Consider it a featureless version of https://github.com/micromatch/picomatch
 * Just turns `*` into a regex of `.*`
 */
export function matchesWildcard(str: string | undefined, glob: string) {
  if (typeof glob !== 'string') {
    throw new TypeError(`Expected glob "${glob}" to be a string.`);
  }

  if (glob === WILDCARD) return true; // matches everything, even an empty string
  if (str === glob) return true; // exact match

  if (!str) return false; // glob wasn't exactly a wildcard; so it doesn't work
  if (!glob.includes(WILDCARD)) return false; // cannot match anymore

  const regex = regexFromGlob(glob);
  if (regex.test(str)) return true;

  return false;
}
