import styled from 'styled-components';
import { rgba } from 'polished';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  overflow-y: scroll;

  width: 100%;
  height: 100vh;
  min-height: stretch;
  min-height: -webkit-fill-available;
  min-height: -moz-available;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${props => rgba(props.theme.colors.black, 0.5)};
`;
