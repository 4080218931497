import { CaretRight } from 'phosphor-react';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { normalizePath } from 'helpers/url';

import { useSiteMetadata } from 'hooks/useSiteMetadata';
import { useContentfulBlogPost } from 'hooks/useContentfulBlogPost';
import { FlexibleLinkComponent } from './FlexibleLinkComponent';

import type { LinkButtonProps } from 'components/Button/types';
import type { HTMLAttributes, FC } from 'react';

export interface BlogLinkProps extends Omit<HTMLAttributes<HTMLAnchorElement>, 'color'> {
  /** Url slug of blog post */
  to: string;
  asButton?: boolean;
  color?: LinkButtonProps['color'];
  appearance?: LinkButtonProps['appearance'];
  linkText?: string;
  showArrowIcon?: boolean;
}

export const BlogLink: FC<BlogLinkProps> = ({
  asButton,
  to,
  title,
  color,
  children = null,
  appearance,
  linkText = 'Read the article',
  showArrowIcon,
  ...props
}) => {
  const { siteUrl } = useSiteMetadata();
  const { defaultLocale } = useLocalizationContext();

  let blogSlug = to;

  if (to.includes('/blog')) {
    const path = to.replace('blog', '');
    blogSlug = path.replace(/\//g, '');
  }

  // This also acts as a check that this blog post actually exists
  const postTitle = useContentfulBlogPost(blogSlug).title;
  const linkTitle = title || `Read the article: ${postTitle}`;

  const path = `/blog/${blogSlug}`;

  return (
    <FlexibleLinkComponent
      openInNewTab={false}
      to={normalizePath({ path, siteUrl })}
      hrefLang={defaultLocale.lang}
      title={linkTitle}
      color={color}
      {...props}
      asButton={asButton}
      appearance={appearance}
      showArrowIcon={showArrowIcon}
    >
      {children || (
        <>
          {linkText}
          <CaretRight weight="bold" />
        </>
      )}
    </FlexibleLinkComponent>
  );
};
