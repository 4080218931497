import styled, { css } from 'styled-components';

import { solidColorMixin } from 'styled/helpers/colors';
import { appearanceMixin } from './appearanceMixin';

import type { BaseButtonInterface } from './types';
import type { StyledProps } from 'styled/types';

type ButtonStyledProps = StyledProps & BaseButtonInterface;

// Do not pass these through to the DOM; StyledComponents/React thinks they should go, they shouldn't.
const omitFromDomProps = [
  'textColor',
  'color',
  'borderColor',
  'appearance',
  'containerBackground',
  'showArrowIcon',
  'iconDirection',
];

export const buttonStyles = css`
  appearance: none;
  text-decoration: none;
  border: 0 solid transparent;
  white-space: nowrap;

  position: relative;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;

  transition-property: color, background-color, border-color;
  transition: all 250ms;

  font-weight: 700;
  letter-spacing: 0.05rem;
  height: ${props => props.theme.components.button.height};
  line-height: ${props => props.theme.components.button.height};
  font-size: ${props => props.theme.fontSize.xxs};
  padding: 0 ${props => props.theme.components.button.padding};
  border-radius: ${props => props.theme.borderRadius};
`;

export const Button = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    if (omitFromDomProps.includes(prop)) return false;
    return defaultValidatorFn(prop); // Must still call this…
  },
})<ButtonStyledProps>`
  ${buttonStyles}

  ${({ color, noUnderline }) =>
    color === 'transparent' &&
    !noUnderline &&
    css`
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-thickness: 1px;
      transition: text-decoration-offset 300ms, text-decoration-thickness 300ms; // NOTE: Not really browser supported…

      &:hover,
      &:focus,
      &:focus-within {
        text-underline-offset: 1px;
        text-decoration-thickness: 2px;
      }
    `}

  ${({ onClick, href, to }) =>
    (onClick || href || to) &&
    css`
      cursor: pointer;
    `}

  ${props => solidColorMixin({ requiredContrast: 'optimal', ...props }, true)}

  ${props =>
    props.borderColor &&
    css`
      border: 2px solid ${props.theme.colors[props.borderColor]};
    `}

  /* Overrides any textColor, backgroundColor, borderColor that is passed */
  ${props =>
    props.appearance &&
    appearanceMixin(
      {
        appearance: props.appearance,
        containerBackground: props.containerBackground,
      },
      true
    )}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      filter: grayscale(80%);
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
