import { BaseButton } from './BaseButton';
import type { LinkButtonProps } from './types';

export const LinkButton = ({
  children,
  'aria-label': ariaLabel,
  to,
  rel,
  target,
  title,
  as,
  ...props
}: LinkButtonProps) => (
  <BaseButton
    {...props}
    rel={rel}
    target={target}
    title={title}
    as={as}
    to={to}
    aria-label={ariaLabel}
    type={undefined}
    role="link"
  >
    {children}
  </BaseButton>
);
