import { encodeUrl } from './encodeUrl';

export function setUtmParameters(path: string, paramName: string, paramValue: string): string {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const utmParameterKeys = [
    'gclid',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
    'campaign_id',
    'device',
    'geo_location',
    'placement',
  ];

  const utmParameters = [];
  for (let i = 0; i < utmParameterKeys.length; i++) {
    if (utmParameterKeys[i] === paramName) {
      const urlParameterValue = paramValue;

      const parsedUrlParam = encodeUrl(urlParameterValue);

      utmParameters.push(`${utmParameterKeys[i]}=${parsedUrlParam}`);
    } else if (urlParams.get(utmParameterKeys[i])) {
      const urlParameterValue = urlParams.get(utmParameterKeys[i]);

      const parsedUrlParam = encodeUrl(urlParameterValue);

      utmParameters.push(`${utmParameterKeys[i]}=${parsedUrlParam}`);
    }
  }

  if (utmParameters.length < 1) return '';

  const utmParameterInUrl = utmParameters.join('&');

  if (path.match(/\.sharesight\.com\//) || (path.startsWith('/') && path.endsWith('/'))) {
    const suffix = path.indexOf('?') !== -1 ? `&${utmParameterInUrl}` : `?${utmParameterInUrl}`;
    return `${suffix}`;
  }

  return '';
}
