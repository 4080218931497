import { Navigation, MobileNavigation } from 'components/Navigation';
import { LoginLink, SignupLink } from 'components/Links';

import { HeaderContainer } from './HeaderContainer.styled';
import { Nav } from './Nav.styled';
import { SharesightLogo } from './Logo.styled';
import { CTAVisibility } from './CTAVisibility.styled';

import type { FC } from 'react';

export interface HeaderProps {}

export const Header: FC<HeaderProps> = () => (
  <HeaderContainer>
    <Nav role="navigation" aria-label="Sharesight">
      <SharesightLogo />

      <Navigation />
      <MobileNavigation />

      <CTAVisibility>
        <LoginLink asButton />
      </CTAVisibility>
      <SignupLink asButton>Sign up</SignupLink>
    </Nav>
  </HeaderContainer>
);
