import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { normalizePath } from 'helpers/url';

import { useGatsbyPage } from 'hooks/useGatsbyPage';
import { useBlogPage } from './useBlogPage';
import { useBlogCategoryPage } from './useBlogCategoryPage';

import { usePartnerPage } from './usePartnerPage';

import { useCampaignPage } from './useCampaignPage';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { ContentfulPage } from './types';

export function useContentfulPage(
  inputPath: string | undefined = undefined,
  forLocale: Locale | undefined = undefined
): ContentfulPage {
  const { currentLocale, basePath } = useLocalizationContext();

  const path = normalizePath({ path: inputPath || basePath });
  const locale = forLocale || currentLocale;

  /** Pages from the blog in Gatsby; everything is under `/blog/` plus the index. */
  const blogPage = useBlogPage({ path });
  const blogCategoryPage = useBlogCategoryPage({ path });

  /** Pages from the partner marketplace in Gatsby; everything is under `/partners/` plus the index. */
  const partnerPage = usePartnerPage({ path, locale });

  /** Pages from the affiliates/campaigns template in Gatsby */
  const campaignPage = useCampaignPage({ path, locale });

  /** These are all Gatsby sources of data for pages, eg. `dividend-tracker` pulls data from the Gatsby space. */
  const gatsbyPage = useGatsbyPage({ path, locale });

  const foundPages = [blogPage, blogCategoryPage, partnerPage, gatsbyPage, campaignPage].filter(
    Boolean
  );

  if (foundPages.length > 1) {
    throw new Error(`Found a path='${path}' ${foundPages.length} times.`);
  }

  // Return the first found page, eg. blog, category, etc…
  if (foundPages[0]) return foundPages[0];

  throw new Error(`Did not find a Contentful Page for path='${path}'.`);
}
