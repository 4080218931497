/*
 * TODO: This really comes from `@sharesight/react/helpers/capitalize`.
 * Use that.
 */

// converts "LoWERcase" or into "Lowercase"
export function capitalize(str: string, lowercase: boolean = true) {
  if (typeof str !== 'string') return str;
  if (lowercase) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// converts "lowercase SENTENCE" into "Lowercase Sentence"
export function capitalizeWords(
  str: string,
  lowercase: boolean = true,
  bracketed: boolean = false
) {
  if (typeof str !== 'string') return str;
  if (lowercase) {
    let regex = /(^| )(\w)/g;
    if (bracketed) regex = /(^| |\()(\w)/g; // works w/ bracketed words too ie: 'some (word)' => 'Some (Word)'
    return str.toLowerCase().replace(regex, s => s.toUpperCase());
  }
  return str.replace(/(^| )(\w)/g, s => s.toUpperCase());
}
