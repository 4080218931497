import type { MenuLink } from '../types';

export const resourcesLinks: MenuLink[] = [
  { label: 'Resources', path: 'resource-hub', corePage: true },
  { title: 'Read the Sharesight blog', label: 'Blog', path: 'blog' },
  {
    label: 'Help Centre',
    helpPath: '/',
  },
  {
    label: 'Community forum',
    title: 'Community Forum', // custom title as it's not a local page
    communityPath: '/',
  },
  { label: 'Webinars & events', path: 'events' },
  { label: 'Become a partner', path: 'become-a-partner' },
  { label: 'Become an affiliate', path: 'affiliates' },
  {
    label: 'Our developer API',
    title: 'Sharesight API documentation', // custom title as it's not a local page
    appPath: '/api',
  },
];
