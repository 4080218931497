import styled, { css } from 'styled-components';
import { flexLayoutMixin } from 'styled/mixins/flexLayoutMixin';
import { gridLayoutMixin } from 'styled/mixins/gridLayoutMixin';

import type { GridOrFlexLayoutMixinProps } from 'styled/mixins/gridOrFlexLayoutMixin';
import type { ContainerInnerProps } from './types';

interface CardsContainerProps extends GridOrFlexLayoutMixinProps {
  columns: ContainerInnerProps['columns'];
}

export const CardsContainer = styled.div`
  /** defaults to the flexLayoutMixin, the gridLayoutMixin is only used when columns > 3 */
  ${(props: CardsContainerProps) =>
    props.columns && props.columns > 3
      ? css`
          ${gridLayoutMixin};
        `
      : css`
          ${flexLayoutMixin}
        `}
`;
