import styled from 'styled-components';

/*
 * NOTE: This is not a link, rather a component that applies link styling
 * Used to draw attention to the CTA in components like Cards, where the whole card is a link
 */

export const LinkAppearance = styled.span`
  text-decoration: underline;
  font-weight: ${props => props.theme.fontWeight.bold};
`;
