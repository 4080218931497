import { getLocalizedUrl } from 'helpers/url';
import { schemas } from './schemas';

import type {
  Schema,
  Product,
  VideoObject,
  GetSchemaProps,
  SchemaJsonObject,
  BuildSchemaContext,
} from './types';

type SchemaKey = keyof typeof schemas;

const hasSchema = (basePath: string | SchemaKey): basePath is SchemaKey => basePath in schemas;
const isOrganization = (schema: Schema): schema is Product => schema['@type'] === 'Organization';
const isVideoObject = (schema: Schema): schema is VideoObject => schema['@type'] === 'VideoObject';

export const getSchema = ({ schema, siteUrl, basePath, currentLocale }: GetSchemaProps): Schema => {
  if (basePath === '/' && isOrganization(schema)) {
    return { ...schema, url: getLocalizedUrl({ locale: currentLocale, siteUrl, path: basePath }) };
  }

  if (basePath === '/features/' && isVideoObject(schema)) {
    return {
      ...schema,
      contentUrl: getLocalizedUrl({ locale: currentLocale, siteUrl, path: basePath }),
    };
  }

  return schema;
};

export const buildSchema = ({
  siteUrl,
  basePath,
  currentLocale,
}: BuildSchemaContext): SchemaJsonObject[] => {
  if (!hasSchema(basePath)) return [];

  const schemaObjects = schemas[basePath];

  return schemaObjects
    .filter(
      schemaObject => !schemaObject.locales || schemaObject.locales.includes(currentLocale.id)
    )
    .map(schemaObject => {
      const schema = getSchema({
        schema: schemaObject.schema,
        siteUrl,
        basePath,
        currentLocale,
      });
      return { key: schemaObject.key, json: JSON.stringify(schema) };
    });
};
