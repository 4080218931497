import { css } from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

import type { ContainerInnerProps } from 'components/Container';

// exported for testing purposes only
export const verticalAlignToAlignItems = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

export interface GridOrFlexLayoutMixinProps
  extends Partial<
    Pick<ContainerInnerProps, 'gap' | 'manageLayout' | 'verticalAlign' | 'scaleMobileGap'>
  > {
  theme: ContainerInnerProps['theme']; // always provided
}

export const gridOrFlexLayoutMixin = ({
  scaleMobileGap = true,
  manageLayout = true,
  verticalAlign = 'center',
  gap = 10,
}: GridOrFlexLayoutMixinProps) => {
  if (manageLayout !== true) return undefined;

  return css`
    gap: ${getDimension(`size${gap}`)};
    align-items: ${verticalAlignToAlignItems[verticalAlign]};
    overflow-x: hidden;

    ${scaleMobileGap &&
    css`
      /** This basically scales gap: 50px => 33.5px => 25px => 16.5px… */
      ${({ theme }) => theme.breakpoints.down('lg')} {
        gap: calc(${getDimension(`size${gap}`)} * 0.6667);
      }

      ${({ theme }) => theme.breakpoints.down('md')} {
        gap: calc(${getDimension(`size${gap}`)} * 0.5);
      }

      ${({ theme }) => theme.breakpoints.down('sm')} {
        gap: calc(${getDimension(`size${gap}`)} * 0.3333);
      }
    `}
  `;
};
