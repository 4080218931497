import { graphql, useStaticQuery } from 'gatsby';
import { getUrl } from 'helpers/url';
import { useSiteMetadata } from 'hooks/useSiteMetadata';
import type { SocialImageGraphQLResponse } from 'components/Seo/types';

interface SocialImage {
  fixed?: { src?: string; width?: number; height?: number };
  file?: { contentType?: string };
}

const SocialImageQuery = graphql`
  query DefaultSocialImage {
    defaultSocialImage: file(sourceInstanceName: { eq: "images" }, name: { eq: "social-image" }) {
      name
      publicURL
      extension
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export const useSocialImage = (image?: SocialImage) => {
  const { defaultSocialImage }: SocialImageGraphQLResponse = useStaticQuery(SocialImageQuery);
  const { siteUrl } = useSiteMetadata();

  // If we have an image (from params), use it!
  if (image?.fixed?.src && image.fixed.width && image.fixed.height && image.file?.contentType) {
    return {
      src: image.fixed.src,
      width: image.fixed.width,
      height: image.fixed.height,
      type: image.file.contentType,
    };
  }

  if (!defaultSocialImage) {
    throw new Error(`Did not find the defaultSocialImage nor the provided image`);
  }

  const fallbackSocialImage = {
    src: getUrl({ siteUrl, path: defaultSocialImage.publicURL }), // NOTE: 🤔 Even though it's named `publicURL`, it's not a URL; it's a relative path.
    width: defaultSocialImage.childImageSharp.gatsbyImageData.width,
    height: defaultSocialImage.childImageSharp.gatsbyImageData.height,
    type: `image/${defaultSocialImage.extension}`,
  };

  return fallbackSocialImage;
};
