import { mergeTranslations } from 'helpers/mergeTranslations';

import { getAppendTitle } from './getAppendTitle';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { ContentfulLocaleNode } from './types';

interface Params {
  nodes: ContentfulLocaleNode[];
  defaultLocale: Locale;
  locale: Locale;
}

export const extendContentfulLocale = ({ nodes, defaultLocale, locale }: Params) => {
  const globalNode = nodes.find(node => node.lang === defaultLocale.lang);
  if (!globalNode) throw new Error(`Missing Default Contentful Locale: '${defaultLocale.lang}'`);

  const localeNode = nodes.find(node => node.lang === locale.lang);
  if (!localeNode) throw new Error(`Missing Contentful Locale: '${locale.lang}'`);

  // NOTE: Throws an error if translations are invalid..
  const translations = mergeTranslations(
    globalNode.translations,
    localeNode.translations,
    `ContentfulLocale::${locale.id}`
  );

  return {
    ...defaultLocale,
    ...locale,
    ...globalNode,
    ...localeNode,
    ...getAppendTitle({ translations }),
    translations,
  };
};
