import { useMemo } from 'react';
import { useProPricing } from 'hooks/useProPricing';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import {
  featuresLinks,
  professionalLinks,
  resourcesLinks,
  aboutLinks,
  pricingLinks,
} from './links';

import type { LinkGroup, MenuLink } from './types';

/**
 * Takes a param - link group and returns the array of links associated with that group
 * If no param is passed, returns the core navigational pages found in the header
 */

const getCorePage = (links: MenuLink[]) => links.filter(link => link.corePage)[0];

export const useNavigationLinks = (linkGroup?: LinkGroup): MenuLink[] => {
  const { path, title } = useProPricing();
  const { appendTitle } = useContentfulLocale();

  const coreLinks: MenuLink[] = useMemo(() => {
    const features = getCorePage(featuresLinks);
    const professionals = getCorePage(professionalLinks);
    const pricing = { label: 'Pricing', path, title };
    const resources = getCorePage(resourcesLinks);
    const about = getCorePage(aboutLinks);

    return [features, professionals, pricing, resources, about];
  }, [path, title]);

  const links = useMemo(() => {
    switch (linkGroup) {
      case 'features':
        return featuresLinks;
      case 'professionals':
        return professionalLinks;
      case 'pricing':
        return pricingLinks;
      case 'resources':
        return resourcesLinks;
      case 'about':
        return aboutLinks;
      default:
        return coreLinks;
    }
  }, [linkGroup, coreLinks]);

  const linksWithAppendTitle = useMemo(() => {
    if (!links) throw new Error('No links found');

    return links.map((link: MenuLink) => {
      if (link.shouldAppendTitle) {
        return {
          ...link,
          title: `${link.title} | ${appendTitle}`,
        };
      }
      return link;
    });
  }, [appendTitle, links]);

  return linksWithAppendTitle;
};
