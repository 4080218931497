import type { ContentfulLocale } from './types';

interface Params {
  translations: ContentfulLocale['translations'];
}

const BASE_TITLE = 'Sharesight';

export const getAppendTitle = ({ translations }: Params) => ({
  appendTitle: `${BASE_TITLE} ${translations.appendTitle || ''}`.trim(),
  socialAppendTitle: BASE_TITLE,
});
