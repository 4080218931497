import { getUrl } from 'helpers/url';

import { useSiteMetadata } from 'hooks/useSiteMetadata';

import { FlexibleLinkComponent } from './FlexibleLinkComponent';

import type { FC } from 'react';
import type { FlexibleLinkProps } from './FlexibleLinkComponent';

export interface CommunityLinkProps extends FlexibleLinkProps {}

export const CommunityLink: FC<CommunityLinkProps> = ({
  asButton,
  to,
  title,
  children,
  ...props
}) => {
  const { communityUrl } = useSiteMetadata();

  const link = getUrl({ path: to, siteUrl: communityUrl });

  return (
    <FlexibleLinkComponent openInNewTab {...props} to={link} title={title} asButton={asButton}>
      {children}
    </FlexibleLinkComponent>
  );
};
