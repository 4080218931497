import { forwardRef, Fragment } from 'react';
import styled from 'styled-components';

import { colorFromPropsMixin } from 'styled/helpers/colors';
import { removeLastChildMarginBottomMixin } from 'styled/helpers/removeLastChildMarginBottom';
import { bodyPaddingMixin } from 'styled/helpers/bodyPaddingMixin';

import { gridLayoutMixin } from 'styled/mixins/gridLayoutMixin';

import type { ContainerBackgroundProps, ContainerInnerProps, ContainerProps } from './types';

export const ContainerBackground = styled.section<ContainerBackgroundProps>`
  ${props => colorFromPropsMixin(props, { defaultBackground: 'white' })}

  &:is(:nth-of-type(even)) {
    // NOTE: We use :is(:nth-child) to avoid leaking styles across Containers.
    ${props =>
      colorFromPropsMixin(props, {
        defaultBackground: 'cream',
      })}
  }
`;

export const ContainerInner = styled.div<ContainerInnerProps>`
  position: relative;
  max-width: ${props => props.theme.maxSiteWidth};
  width: 100%;
  margin: 0 auto;

  ${bodyPaddingMixin}
  ${gridLayoutMixin}
  ${removeLastChildMarginBottomMixin}
`;

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      id,
      'aria-label': ariaLabel,
      children,
      className,
      role,

      color,
      background,

      containerAs,
      innerAs,

      columns = 5,
      gap = 10,
      manageLayout = true,
      padding = 15,
      wrapMobile = 'soft',
      scaleMobileGap = true,
      scaleMobilePadding = false,
      bottomPadding,
      verticalAlign = 'center',
      skipColoration = false,
    },
    ref
  ) => {
    /**
     * If skipColoration=true, we wrap this in a div so this container will be not be included
     * in the nth-of-type(even) selector in ContainerBackground.
     * This enables odd-even colouring to continue over this container.
     *
     * e.g.          odd-container -> this-container -> even-container
     * rather than   odd-container -> this-container -> odd-container
     *
     * This is because nth-of-type is based on the HTML structure
     * (based on source order in relation to its siblings).
     * So wrapping it excludes it from its siblings.
     */
    const Wrapper = skipColoration ? 'div' : Fragment;
    return (
      <Wrapper>
        <ContainerBackground
          as={containerAs}
          ref={ref}
          className={className}
          role={role}
          aria-label={ariaLabel}
          id={id}
          color={color}
          background={background}
        >
          <ContainerInner
            as={innerAs}
            columns={columns}
            gap={gap}
            manageLayout={manageLayout}
            padding={padding}
            verticalAlign={verticalAlign}
            scaleMobileGap={scaleMobileGap}
            scaleMobilePadding={scaleMobilePadding}
            bottomPadding={bottomPadding}
            wrapMobile={wrapMobile}
          >
            {children}
          </ContainerInner>
        </ContainerBackground>
      </Wrapper>
    );
  }
);
Container.displayName = 'Container';
