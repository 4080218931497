import { getUrl } from 'helpers/url';

import { useSiteMetadata } from 'hooks/useSiteMetadata';

import { FlexibleLinkComponent } from './FlexibleLinkComponent';

import type { FC } from 'react';
import type { FlexibleLinkProps } from './FlexibleLinkComponent';

export interface AppLinkProps extends FlexibleLinkProps {}

export const AppLink: FC<AppLinkProps> = ({
  asButton,
  appearance,
  containerBackground,
  to,
  title,
  openInNewTab = true,
  children,
  ...props
}) => {
  const { appUrl } = useSiteMetadata();

  const link = getUrl({ path: to, siteUrl: appUrl });

  return (
    <FlexibleLinkComponent
      openInNewTab={openInNewTab}
      {...props}
      to={link}
      title={title}
      asButton={asButton}
      appearance={appearance}
      containerBackground={containerBackground}
    >
      {children}
    </FlexibleLinkComponent>
  );
};
