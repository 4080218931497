import styled, { keyframes } from 'styled-components';
import ReactPlayer from 'react-player/lazy';

import { useCallback, useState } from 'react';
import icon from 'images/video/play-icon.svg';
import type { FC } from 'react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// https://www.npmjs.com/package/react-player
const Container = styled.div<{ background: 'placeholder' | 'black' | 'transparent' }>`
  background-color: ${props =>
    props.background === 'placeholder' ? 'rgba(0, 0, 0, 0.1)' : props.background};
  position: relative;
  padding-top: 56.25%;

  // Styling thumbnail - classname built into react-player
  .react-player__preview {
    // fade in animation
    animation: ${fadeIn} 0.4s;

    // hover effect is applied to play icon
    &:hover,
    &:focus,
    &:focus-within {
      img {
        opacity: 0.8;
      }
    }
  }
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledIcon = styled.img`
  width: 15%;
  opacity: 1;
  transition: 250ms opacity;
  will-change: opacity;
`;

export interface VideoProps {
  /**
   *  NOTE: For now we restrict this to be only youtube videos.
   *  In future we may want to extend this to include other sources like vimeo!
   */
  videoUrl: `https://www.youtube.com/${string}`;
  /**
   * Image to use as the video thumbnail. If not provided, uses video's original thumbnail.
   * NOTE: This should be an svg for better performance.
   */
  thumbnailUrl?: string;
}

/** This is for youtube specifically */
const YOUTUBE_OPTIONS = {
  playerVars: {
    modestbranding: 1,
    rel: 0,
  },
} as const;

const PlayIcon: FC = () => <StyledIcon src={icon} alt="play" />;

export const VideoPlayer: FC<VideoProps> = ({ videoUrl, thumbnailUrl }) => {
  /*
   * 'placeholder' results in a transparent black bg
   * - this is seen while the image placeholder is loading.
   */
  const [background, setBackground] = useState<'placeholder' | 'black' | 'transparent'>(
    'placeholder'
  );

  /**
   * Although we are using react-player/lazy here,
   * it seems like lazy loading only works when using light mode.
   * See https://www.npmjs.com/package/react-player#light-player for `light` prop details
   */
  const light = thumbnailUrl || true;

  return (
    <Container background={background}>
      <StyledReactPlayer
        config={YOUTUBE_OPTIONS}
        url={videoUrl}
        light={light}
        /*
         * Video should auto play as we are in light mode.
         * Without this user has to click twice on video to play,
         * as it is not loaded until after the first click.
         */
        playing
        width="100%"
        height="100%"
        // custom play icon
        playIcon={<PlayIcon />}
        /*
         * Hack to make transition between thumbnail disappearing and video appearing look smooth!
         * The background should become transparent when the video has loaded otherwise we get black edge
         * artifacts sometimes (padding-top is not an exact px value).
         */
        onClickPreview={useCallback(() => setBackground('black'), [setBackground])}
        onStart={useCallback(() => setBackground('transparent'), [setBackground])}
      />
    </Container>
  );
};
