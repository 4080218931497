import { Link } from 'gatsby'; // eslint-disable-line no-restricted-imports -- This is the only place we should use this <Link />
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import styled, { css } from 'styled-components';

import { useEffect, useState } from 'react';
import { HrefButton, LinkButton } from 'components/Button';
import { useIsRemotePath } from 'hooks/useIsRemotePath';

import { getUtmParameters } from 'helpers/getUtmParameters';
import { setUtmParameters } from 'helpers/setUtmParameters';
import { isBrowser } from 'helpers/isBrowser';

import type { BaseButtonInterface } from 'components/Button/types';
import type { RefObject, FC } from 'react';
import type { GatsbyLinkProps } from 'gatsby';

export type FlexibleLinkProps = GatsbyLinkProps<any> & {
  asButton?: boolean;
  color?: BaseButtonInterface['color'];
  textColor?: BaseButtonInterface['textColor'];
  borderColor?: BaseButtonInterface['borderColor'];
  appearance?: BaseButtonInterface['appearance'];
  containerBackground?: BaseButtonInterface['containerBackground'];
  showArrowIcon?: BaseButtonInterface['showArrowIcon'];
  iconDirection?: BaseButtonInterface['iconDirection'];
  urlParams?: { paramName: string; paramValue: string }[];
  to: string;
  disabled?: boolean;
  noUnderline?: BaseButtonInterface['noUnderline'];
  fullWidth?: boolean;
  /**
   * if undefined, link opens in same tab unless it's a remote path
   */
  openInNewTab?: boolean;
  ref?: RefObject<any>; // this fixes a conflict between react and styled components
};

/**
 * FlexibleLinkComponent
 * Picks the right base element from AnchorLink and Link and styles as a Button when necessary.
 */
const FlexibleLinkComponentBase: FC<FlexibleLinkProps> = ({
  asButton,
  color,
  noUnderline,
  textColor,
  borderColor,
  appearance,
  containerBackground,
  to,
  openInNewTab,
  showArrowIcon,
  iconDirection,
  urlParams,
  ...props
}) => {
  const [utmParams, setUtmParams] = useState('');
  let target = openInNewTab ? '_blank' : '_self';
  let rel = openInNewTab ? 'noopener noreferrer' : undefined;

  const buttonProps = {
    color,
    borderColor,
    textColor,
    noUnderline,
    appearance,
    containerBackground,
    showArrowIcon,
    iconDirection,
  };

  useEffect(() => {
    let params;
    if (isBrowser) {
      params =
        urlParams && urlParams.length > 0
          ? setUtmParameters(to, urlParams[0].paramName, urlParams[0].paramValue)
          : getUtmParameters(to);
    }

    setUtmParams(params || '');
  }, [urlParams, to]);

  const href = `${to}${utmParams}`;

  const isRemote = useIsRemotePath(href);

  if (isRemote) {
    // If openInNewTab is not specified, we default to true for remote links.
    if (openInNewTab === undefined) {
      target = '_blank';
      rel = 'noopener noreferrer';
    }

    const LinkComponent = 'a';

    if (asButton) {
      return (
        <HrefButton
          {...props}
          {...buttonProps}
          as={LinkComponent}
          href={href}
          target={target}
          rel={rel}
          type={undefined}
        />
      );
    }
    return <LinkComponent {...props} href={href} target={target} rel={rel} />;
  }

  const LinkComponent = href.includes('#') ? AnchorLink : Link;

  if (asButton) {
    return (
      <LinkButton
        {...props}
        {...buttonProps}
        as={LinkComponent}
        to={href}
        target={target}
        rel={rel}
        type={undefined}
      />
    );
  }
  return <LinkComponent {...props} to={href} target={target} rel={rel} />;
};

/**
 * We can override the global link style by passing textColor prop.
 */
export const FlexibleLinkComponent = styled(FlexibleLinkComponentBase)<FlexibleLinkProps>`
  ${props =>
    props.textColor &&
    css`
      color: ${props.theme.colors[props.textColor]};
    `}

  ${props =>
    !props.asButton &&
    props.noUnderline &&
    css`
      text-decoration: none;
    `}

    ${props =>
    props.fullWidth &&
    css`
      width: 100%;
      display: flex;
      justify-content: center;
    `}
`;
