import { getBlogPostUrlSlug } from './url/getBlogPostUrlSlug';

import type { BlogPost, ContentfulBlogPostRaw } from 'types/blog/blogPost';
import type { SimpleBlogPost, SimpleBlogPostNode } from 'types/blog/simpleBlogPost';

export const getParsedSimpleBlogPost = (post: SimpleBlogPostNode): SimpleBlogPost => ({
  id: post.contentfulId,
  title: post.title.title,
  urlSlug: getBlogPostUrlSlug(post),
  featuredImage: post.featuredImage
    ? {
        src: post.featuredImage.fixed.src,
        title: post.featuredImage.title,
      }
    : undefined,
});

export const getParsedBlogPost = (post: ContentfulBlogPostRaw): BlogPost => {
  const currentCategories =
    post.categories?.map(category => ({
      name: category.name.name,
      urlSlug: `/blog/${category.urlSlug}`,
    })) || [];

  const imageSrc = post.image.fixed?.src ? post.image.fixed.src : undefined;
  if (!post.author.display_name.display_name) {
    throw new Error(`No author.display_name.display_name was found for url=${post.urlSlug}`);
  }

  return {
    id: post.contentfulId,
    title: post.title.title,
    image: {
      gatsbyImageData: post.image.gatsbyImageData,
      title: post.image.title || post.title.title,
      src: imageSrc,
    },
    content: post.content?.childMarkdownRemark.html || '',
    categories: currentCategories,
    urlSlug: getBlogPostUrlSlug(post),
    author: post.author.display_name.display_name,
    authorTitle: post.author?.Title?.Title,
    authorCompany: post.author?.company?.company,
    description: post.metaDescription.metaDescription,
    date: post.created_at,
  };
};
