/*
 * It appears the gatsby does not automatically encode special characters
 * This function manually encodes the following special characters:
 * [ ] { } ” ~ # < > $ . + ! ‘ ( ) , and spaces
 */

export const encodeUrl = (urlParam: string | null) => {
  if (!urlParam) return '';
  let encoded = urlParam;

  if (urlParam.includes(' ') || urlParam.includes('+')) {
    encoded = encoded?.replace(/\+|\s/g, '%20');
  }

  if (urlParam.includes('[')) {
    encoded = encoded?.replace(/\[/g, '%5b');
  }

  if (urlParam.includes(']')) {
    encoded = encoded?.replace(/\]/g, '%5d');
  }

  if (urlParam.includes('{')) {
    encoded = encoded?.replace(/\{/g, '%7b');
  }

  if (urlParam.includes('}')) {
    encoded = encoded?.replace(/\}/g, '%7d');
  }

  if (urlParam.includes('“') || urlParam.includes('"')) {
    encoded = encoded?.replace(/\“|\"/g, '%93');
  }

  if (urlParam.includes('”')) {
    encoded = encoded?.replace(/\”/g, '%94');
  }

  if (urlParam.includes('~')) {
    encoded = encoded?.replace(/\~/g, '%7e');
  }

  if (urlParam.includes('#')) {
    encoded = encoded?.replace(/\#/g, '%23');
  }

  if (urlParam.includes('<')) {
    encoded = encoded?.replace(/\</g, '%3c');
  }

  if (urlParam.includes('>')) {
    encoded = encoded?.replace(/\>/g, '%3e');
  }

  if (urlParam.includes('$')) {
    encoded = encoded?.replace(/\$/g, '%24');
  }

  if (urlParam.includes('‘') || urlParam.includes("'")) {
    encoded = encoded?.replace(/\‘|\'/g, '%91');
  }

  if (urlParam.includes('’')) {
    encoded = encoded?.replace(/\’/g, '%92');
  }

  if (urlParam.includes('(')) {
    encoded = encoded?.replace(/\(/g, '%28');
  }

  if (urlParam.includes(')')) {
    encoded = encoded?.replace(/\)/g, '%29');
  }

  if (urlParam.includes('*')) {
    encoded = encoded?.replace(/\*/g, '%2a');
  }

  if (urlParam.includes(',')) {
    encoded = encoded?.replace(/\,/g, '%2c');
  }

  if (urlParam.includes('`')) {
    encoded = encoded?.replace(/\`/g, '%60');
  }

  return encoded;
};
