import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { getLocalizedPath } from 'helpers/url';

import { useGatsbyPage } from 'hooks/useGatsbyPage';
import { FlexibleLinkComponent } from './FlexibleLinkComponent';

import type { FC } from 'react';
import type { FlexibleLinkProps } from './FlexibleLinkComponent';
import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';

export interface GatsbyLinkProps extends Omit<FlexibleLinkProps, 'openInNewTab'> {
  toLocale?: Locale;
}
/**
 * This link is used for all 'gatsby' pages e.g. faq, dividend-tracker, 404
 * Not to be used for partner, blog, campaign pages, app links or external links - we have different components for this
 */
export const GatsbyLink: FC<GatsbyLinkProps> = ({
  asButton = false,
  children = null,
  color = undefined,
  disabled = false,
  noUnderline = false,
  textColor = undefined,
  title = undefined,
  to,
  toLocale = undefined,
  showArrowIcon = false,
  iconDirection = 'right',
  ...props
}) => {
  const { currentLocale } = useLocalizationContext();
  const locale = toLocale || currentLocale;

  const gatsbyPage = useGatsbyPage({ path: to, locale });
  if (!gatsbyPage) throw new Error(`GatsbyLink: No gatsby page found with path=${to}`);

  const link = getLocalizedPath({ path: to, locale });

  return (
    <FlexibleLinkComponent
      {...props}
      openInNewTab={false}
      to={link}
      title={title || gatsbyPage.seoProps.title}
      textColor={textColor}
      color={color}
      hrefLang={locale.lang}
      disabled={disabled}
      asButton={asButton}
      noUnderline={noUnderline}
      showArrowIcon={showArrowIcon}
      iconDirection={iconDirection}
    >
      {children || title || gatsbyPage.seoProps.rawTitle}
    </FlexibleLinkComponent>
  );
};
