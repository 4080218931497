import styled from 'styled-components';

import { LoginLink, SignupLink } from 'components/Links';

import { getDimension } from 'styled/helpers/getDimension';
import type { FC } from 'react';

const Container = styled.div`
  background: ${props => props.theme.colors.black};

  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-gap: ${getDimension('size4')};
  justify-content: center;

  margin-top: ${getDimension('size4')};
  padding: ${getDimension('size4')};

  // HACK: We stretch this to cover the overlay.width
  position: sticky;
  bottom: 0;
  margin-left: -${getDimension('size10')};
  margin-right: -${getDimension('size10')};
  width: calc(100% + ${getDimension('size10')} * 2);

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: fixed;
    margin-left: -${getDimension('size4')};
    margin-right: -${getDimension('size4')};
    width: calc(100% + ${getDimension('size4')} * 2);
  }
`;

export const CTA: FC<{}> = () => (
  <Container>
    <LoginLink asButton appearance="secondary" containerBackground="negative" />
    <SignupLink asButton appearance="primary" containerBackground="negative" />
  </Container>
);
