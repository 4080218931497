import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { useContentfulPage } from 'hooks/useContentfulPage';

import { COOKIE_CAMPAIGN_CODE } from './constants';

import type { FC } from 'react';

/**
 * This sets a cookie of `campaign_code` against all of `.sharesight.com`.
 * This is strictly a component with a side-effect and does not render anything.
 */
export const CampaignCookie: FC = () => {
  const { campaignCode } = useContentfulPage();
  const [_cookie, setCookie] = useCookies([COOKIE_CAMPAIGN_CODE]);

  useEffect(() => {
    if (campaignCode) {
      const maxAge = 30 * 24 * 60 * 60; // 30 days (in seconds)

      setCookie(COOKIE_CAMPAIGN_CODE, campaignCode, {
        path: '/',
        maxAge,
        expires: new Date(Date.now() + maxAge * 1000), // expiry 30 days from now
        domain: '.sharesight.com',
        secure: true,
      });
    }
  }, [campaignCode, setCookie]);

  return null;
};
