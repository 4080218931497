import styled, { css } from 'styled-components';

export const removeLastChildMarginBottomMixin = css`
  & > *:last-child {
    /* Remove the margin-bottom from the last child */
    margin-bottom: 0;
  }
`;

export const NoLastChildMarginBottom = styled.div`
  ${removeLastChildMarginBottomMixin}
`;
