import styled, { css } from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';
import { removeLastChildMarginBottomMixin } from 'styled/helpers/removeLastChildMarginBottom';

import type { TooltipDirection } from './Tooltip';

/**
 * Gets the transformation needed turn a square into a diamond.
 * - exported for testing
 */
export const getArrowTransform = (direction: TooltipDirection) => {
  // very specific and hardcoded values, but this is unlikely to change
  if (direction === 'right' || direction === 'left') {
    // sideways diamond
    return 'scale(1.3, 0.8) rotate(45deg)';
  }

  // upright diamond
  return 'scale(0.8, 1.3) rotate(45deg)';
};

/**
 * Styling the tooltip popup - Because it will be attached to document.body, we
 * include this css in GlobalStyles
 *
 * The class names we are targeting here are built into rc-toolip
 */
export const tooltipStyles = () => css`
  // Here we are changing the default tooltip arrow style
  // Originally it uses borders to form the arrow, but this doesn't work well with
  // box-shadow, so we are using a transformed square instead.
  .rc-tooltip-arrow {
    border: none;

    // very specific size to make arrow look okay, unlikely to change
    height: 9px;
    width: 9px;

    background-color: white;
    box-shadow: ${props => props.theme.boxShadow};
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow {
    transform: ${getArrowTransform('top')};
  }

  .rc-tooltip-placement-bottom .rc-tooltip-arrow {
    transform: ${getArrowTransform('bottom')};
  }

  .rc-tooltip-placement-right .rc-tooltip-arrow {
    transform: ${getArrowTransform('right')};
  }

  .rc-tooltip-placement-left .rc-tooltip-arrow {
    transform: ${getArrowTransform('left')};
  }

  .rc-tooltip-inner {
    // this needs to appear above the arrow
    position: relative;
    z-index: 10;

    border: none;
    padding: ${getDimension('size6')};

    &,
    & p {
      font-size: ${props => props.theme.fontSize.xxs};
      font-weight: ${props => props.theme.fontWeight.normal};
    }

    ${removeLastChildMarginBottomMixin}
  }

  .rc-tooltip {
    opacity: 1;
    padding: 0;

    border: none;
    ${({ theme }) => css`
      background-color: ${theme.colors.white};
      border-radius: ${theme.borderRadius};
      box-shadow: ${theme.boxShadow};
    `}
  }
`;

export const TooltipContainer = styled.span`
  width: max-content;
  display: inline-flex;
  align-items: center;
`;
