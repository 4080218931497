import { ArrowRight, ArrowLeft } from 'phosphor-react';
import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const RightArrowIcon = styled(ArrowRight)`
  margin-left: ${getDimension('size2')};
`;

export const LeftArrowIcon = styled(ArrowLeft)`
  margin-right: ${getDimension('size2')};
`;
