import styled from 'styled-components';

import { Section } from './Section.styled';
import { VideoPlayer } from './VideoPlayer.styled';

import type { FC } from 'react';
import type { SectionProps } from './Section.styled';
import type { VideoProps } from './VideoPlayer.styled';

const Container = styled(Section)`
  text-align: center;
`;

interface SectionVideoProps extends Omit<SectionProps, 'children'> {
  children?: SectionProps['children'];
  videoUrl?: VideoProps['videoUrl'];
  /**
   * Image to use as the video thumbnail. If not provided, uses video's original thumbnail.
   */
  thumbnailUrl?: VideoProps['thumbnailUrl'];
}

export const SectionVideo: FC<SectionVideoProps> = ({
  children,
  span = 2,
  videoUrl,
  thumbnailUrl,
}) => (
  <Container span={span}>
    {videoUrl && <VideoPlayer videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />}
    {children}
  </Container>
);
