import { css } from 'styled-components';
import { gridOrFlexLayoutMixin } from './gridOrFlexLayoutMixin';

import type { GridOrFlexLayoutMixinProps } from './gridOrFlexLayoutMixin';
import type { ContainerInnerProps } from 'components/Container';

export interface GridMixinProps extends GridOrFlexLayoutMixinProps {
  wrapMobile?: ContainerInnerProps['wrapMobile'];
  columns?: ContainerInnerProps['columns'];
}

export const gridLayoutMixin = ({
  columns = 3,
  gap = 10,
  scaleMobileGap = true,
  manageLayout = true,
  theme,
  wrapMobile = 'soft',
  verticalAlign = 'center',
}: GridMixinProps) => {
  if (manageLayout !== true) return undefined;

  return css`
    ${gridOrFlexLayoutMixin({
      gap,
      scaleMobileGap,
      manageLayout,
      theme,
      verticalAlign,
    })}

    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;

    ${wrapMobile === 'soft' &&
    css`
      grid-auto-flow: unset; /* use the grid-template below */
      grid-template-columns: repeat(auto-fit, minmax(${900 / columns}px, 1fr));

      ${theme.breakpoints.down('lg')} {
        /** WARNING: This sets a min-width of 375px when columns == 2: */
        grid-template-columns: repeat(auto-fit, minmax(${750 / columns}px, 1fr));
      }

      ${theme.breakpoints.down('md')} {
        grid-template-columns: 1fr; /** This should always span screen width. */
        grid-auto-flow: row;
      }
    `}

    /**
     * Only wraps on small screens, and ensures all items span 1 grid column
     */
    ${wrapMobile === 'hard' &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      ${theme.breakpoints.down('md')} {
        grid-template-columns: 1fr;
        grid-auto-flow: row;

        > * {
          span: 1;
        }
      }
    `}
  `;
};
