import type { MenuLink } from '../types';

export const aboutLinks: MenuLink[] = [
  { label: 'About us', path: 'about-us', corePage: true },
  { label: 'Our team', path: 'team' },
  {
    label: 'Brand assets',
    externalPath: 'https://sharesight.atlassian.net/wiki/spaces/BG/overview',
  },
];
