import { useMemo } from 'react';
import { Overlay as BaseOverlay } from 'components/Overlay';

import { useNavigationLinks } from 'hooks/useNavigationLinks';
import { CTA } from './CTA.styled';
import { Link } from '../Menu';
import type { FC } from 'react';

export interface OverlayProps {
  handleClose: () => void;
}

export const Overlay: FC<OverlayProps> = ({ handleClose }) => {
  const links = useNavigationLinks();

  const renderLinks = useMemo(
    () => links.map(link => <Link key={link.path} link={link} />),
    [links]
  );

  return (
    <BaseOverlay handleClose={handleClose} noBottomPadding>
      <div>{renderLinks}</div>
      <CTA />
    </BaseOverlay>
  );
};
