import TagManager from 'react-gtm-module';

export const initializeGoogleTagManager = () => {
  const tagManagerArgs = {
    gtmId: process.env.GTM_CONTAINER_ID || 'GTM-5HSWD9', // default is our production container
    includeInDevelopment: false,
    defaultDataLayer: { platform: 'gatsby' },
    enableWebVitalsTracking: true,

    routeChangeEventName: 'gatsby-route-change',
  };

  TagManager.initialize(tagManagerArgs);
};
