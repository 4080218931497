import styled, { css } from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';
import { Box } from 'components/Box';
import { Section } from './Section.styled';

import type { ReactNode, FC } from 'react';
import type { Color, Size } from 'styled/types';
import type { SectionProps } from './Section.styled';

interface ContainerProps extends SectionProps {
  firstOnMobile: boolean;
}

const Container = styled(Section)<ContainerProps>`
  text-align: center;

  ${({ firstOnMobile }) =>
    firstOnMobile &&
    css`
      ${({ theme }) => theme.breakpoints.down('md')} {
        order: -1;
      }
    `};
`;

const RelativeContainer = styled.div<SectionProps>`
  position: relative;
  width: auto;
  min-width: 100%;

  ${({ center }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

interface ImageContainerProps {
  border?: Color;
  boxShadow: boolean;
  borderRadius: boolean;
}

// exported to use in tests
export const IMAGE_BORDER_PX = '4px';

const ImageContainer = styled.div<ImageContainerProps>`
  width: fit-content;
  margin: 0 auto;

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${getDimension('size2')};
      overflow: hidden; /** border-radius over the image */
    `}

  ${({ border, theme }) =>
    border &&
    css`
      border: ${IMAGE_BORDER_PX} solid ${theme.colors[border]};
    `}

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: 0px ${IMAGE_BORDER_PX} ${IMAGE_BORDER_PX} rgba(0, 0, 0, 0.25);
    `}
`;

const Overlay = styled.div`
  max-width: 50%;
  position: absolute;
  bottom: -${getDimension('size8')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 250px;
    bottom: -${getDimension('size10')};
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

export interface SectionImageProps extends Omit<SectionProps, 'children'> {
  image: ReactNode;
  overlay?: ReactNode;
  border?: Color;
  boxShadow?: boolean;
  borderRadius?: boolean;
  /** Whether this should be the first item in its parent container when in mobile view */
  firstOnMobile?: boolean;
  padding?: Size;
  maxWidth?: string;
}

export const SectionImage: FC<SectionImageProps> = ({
  image,
  overlay = undefined,
  boxShadow = true,
  borderRadius = true,
  border,
  firstOnMobile = false,
  span = 3,
  maxWidth = undefined,
  padding = 0,
  center,
}) => (
  <Container firstOnMobile={firstOnMobile} span={span}>
    <RelativeContainer center={center}>
      <Box padding={padding} maxWidth={maxWidth}>
        <ImageContainer border={border} borderRadius={borderRadius} boxShadow={boxShadow}>
          {image}
        </ImageContainer>
      </Box>

      {overlay && <Overlay>{overlay}</Overlay>}
    </RelativeContainer>
  </Container>
);
