import { math } from 'polished';

import { getDimension } from '../getDimension';
import type { Size, Theme } from 'styled/types';

interface ScaledDimensionParams {
  size: Size;
  scale: number;
  theme?: Theme;
}

export const getScaledDimension = ({ size, scale, theme }: ScaledDimensionParams) =>
  math(`${getDimension(`size${size}`)({ theme })} * ${scale}`);
