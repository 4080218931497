import { useMemo } from 'react';
import { useNavigationLinks } from 'hooks/useNavigationLinks';
import { MenuBar } from './Menu/MenuBar.styled';

import { MenuTarget } from './Menu';
import type { FC } from 'react';

export const Navigation: FC<{}> = () => {
  const links = useNavigationLinks();

  const renderLinks = useMemo(
    () =>
      links.map(link => {
        if (!link.path) return null;

        return <MenuTarget key={link.path} to={link.path} title={link.title} label={link.label} />;
      }),
    [links]
  );

  return <MenuBar>{renderLinks}</MenuBar>;
};
