import type { MenuLink } from '../types';

export const pricingLinks: MenuLink[] = [
  { label: 'Pricing', path: '/pricing', title: 'Pricing', corePage: true },
  {
    label: 'Professional pricing',
    title: `Professional pricing`, // custom title as it's not a local page
    path: '/professionals/#pricing',
    bold: true,
    shouldAppendTitle: true,
  },
];
