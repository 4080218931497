import { useEffect } from 'react';

import type { RefObject } from 'react';

type ListenerEvent = FocusEvent | MouseEvent | TouchEvent;

export function useOnOutsideInteract<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: ListenerEvent) => void
) {
  useEffect(() => {
    const listener = (event: ListenerEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el === event.target || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener, { passive: true });
    document.addEventListener('focusin', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.addEventListener('focusin', listener);
    };
  }, [ref, handler]);
}
