import { css } from 'styled-components';

import { buttonBackground, buttonBorder, buttonTextColor } from './buttonAppearances';
import type { Appearance, BaseButtonInterface } from './types';

interface AppearanceMixinProps {
  appearance: Appearance;
  containerBackground: BaseButtonInterface['containerBackground'];
}

export const appearanceMixin = (
  { appearance, containerBackground = 'positive' }: AppearanceMixinProps,
  onEvent: boolean = false
) => css`
  background-color: ${buttonBackground[containerBackground].noEvent[appearance]};
  color: ${buttonTextColor[containerBackground].noEvent[appearance]};
  border: ${buttonBorder[containerBackground].noEvent[appearance]};

  ${(appearance === 'tertiary' || appearance === 'link') &&
  css`
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    transition: text-decoration-offset 300ms, text-decoration-thickness 300ms; // NOTE: Not really browser supported…

    &:hover,
    &:focus,
    &:focus-within {
      text-underline-offset: 1px;
      text-decoration-thickness: 2px;
    }
  `}

  ${onEvent &&
  css`
    &:hover,
    &:focus,
    &:focus-within {
      background-color: ${buttonBackground[containerBackground].hover[appearance]};
      color: ${buttonTextColor[containerBackground].hover[appearance]};
      border: ${buttonBorder[containerBackground].hover[appearance]};
    }

    &:active {
      background-color: ${buttonBackground[containerBackground].active[appearance]};
      color: ${buttonTextColor[containerBackground].active[appearance]};
      border: ${buttonBorder[containerBackground].active[appearance]};
    }
  `};
`;
