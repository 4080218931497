import { Button as StyledButton } from './Button.styled';

import { RightArrowIcon, LeftArrowIcon } from './ArrowIcons.styled';

import type { BaseButtonInterface } from './types';
import type { FC } from 'react';

export const BaseButton: FC<BaseButtonInterface> = ({
  'aria-label': ariaLabel,
  as = 'button',
  children,
  className,
  color = 'primary',
  borderColor = undefined,
  appearance = undefined,
  containerBackground = 'positive',
  disabled = false,
  href = undefined,
  noUnderline = false,
  onClick,
  rel,
  target,
  textColor = undefined,
  title,
  to = undefined,
  type,
  showArrowIcon = false,
  iconDirection = 'right',
  role,
}: BaseButtonInterface) => (
  <StyledButton
    aria-label={ariaLabel}
    as={as}
    className={className}
    color={color}
    disabled={disabled}
    href={href}
    noUnderline={noUnderline}
    onClick={onClick}
    rel={rel}
    target={target}
    textColor={textColor}
    appearance={appearance}
    containerBackground={containerBackground}
    title={title}
    to={to}
    type={type}
    borderColor={borderColor}
    role={role}
  >
    {showArrowIcon && iconDirection === 'left' && (
      <LeftArrowIcon weight="bold" aria-label="left arrow" />
    )}
    {children}
    {showArrowIcon && iconDirection === 'right' && (
      <RightArrowIcon weight="bold" aria-label="right arrow" />
    )}
  </StyledButton>
);
