import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { normalizePath } from 'helpers/url';
import { getBlogPostUrlSlug } from 'helpers/url/getBlogPostUrlSlug';
import { useSocialImage } from './useSocialImage';

import type { ContentfulPage, BlogPostPagesGraphQLResponse } from './types';

const query = graphql`
  query useBlogPageQuery {
    allContentfulPost(sort: { fields: created_at, order: DESC }) {
      nodes {
        contentfulId: contentful_id
        id
        title {
          title
        }
        seoTitle
        urlSlug
        image: featuredImage {
          title
          fixed(width: 750, quality: 100) {
            src
            width
            height
          }
          file {
            contentType
          }
        }
        metaDescription {
          metaDescription
        }
        author {
          display_name {
            display_name
          }
          Title {
            Title
          }
          company {
            company
          }
        }
        categories {
          name {
            name
          }
          urlSlug
        }
        created_at
      }
    }
  }
`;

/** Get a ContentfulPage data object for a blog page. */
export const useBlogPage = ({ path }: { path: string }): ContentfulPage | undefined => {
  const { allContentfulPost }: BlogPostPagesGraphQLResponse = useStaticQuery(query);

  const blogPost = useMemo(
    () =>
      allContentfulPost.nodes.find(post => {
        // In some cases, the urlSlug is not set in contentful
        const blogPath = getBlogPostUrlSlug(post);

        return normalizePath({ path }) === blogPath;
      }),
    [allContentfulPost, path]
  );

  const socialImage = useSocialImage(blogPost?.image);

  if (!blogPost) return undefined;

  const rawTitle = blogPost.seoTitle || blogPost.title.title;
  const title = `${rawTitle} | Sharesight Blog`;
  const description = blogPost.metaDescription.metaDescription;

  return {
    // A blog post has none of these:
    cards: [],
    accordionItems: [],
    sections: [],
    translations: {},

    seoProps: {
      noIndex: false, // always index blogs
      title,
      rawTitle,
      description,
      socialTitle: title,
      socialImage,
      socialDescription: description,
    },
  };
};
