import { getLocalizedUrl } from './getLocalizedUrl';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { UnLocalizedPath } from 'data/unLocalizedPaths';

interface GetHelpUrlOptions {
  locale: Locale;
  defaultLocale: Locale;
  path: string;
  helpUrl: string;
  unLocalizedPaths?: UnLocalizedPath[];
}

export function getHelpUrl({
  locale,
  path,
  defaultLocale,
  helpUrl,
  unLocalizedPaths = [], // NOTE: We do not have a list of unlocalized help paths.
}: GetHelpUrlOptions) {
  let forLocale = locale;

  /**
   * NOTE: We do not support US Locales on the Help Site
   * Fallback to defaultLocale until we add it.
   */
  if (forLocale.id === 'us') forLocale = defaultLocale;

  return getLocalizedUrl({
    locale: forLocale,
    siteUrl: helpUrl,
    path,
    unLocalizedPaths,
  });
}
