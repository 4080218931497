import { Container } from './Container.styled';

import type { ContainerProps } from './types';
import type { ReactNode, FC } from 'react';

export const HeroContainer: FC<{
  children: ReactNode;
  skipColoration?: ContainerProps['skipColoration'];
  background?: ContainerProps['background'];
  wrapMobile?: ContainerProps['wrapMobile'];
  columns?: ContainerProps['columns'];
  id?: ContainerProps['id'];
  padding?: ContainerProps['padding'];
  manageLayout?: ContainerProps['manageLayout'];
}> = ({
  children,
  skipColoration,
  background,
  wrapMobile,
  columns,
  id,
  padding = 15,
  manageLayout,
}) => (
  <Container
    scaleMobileGap={false}
    scaleMobilePadding
    padding={padding}
    skipColoration={skipColoration}
    background={background}
    wrapMobile={wrapMobile}
    columns={columns}
    id={id}
    manageLayout={manageLayout}
  >
    {children}
  </Container>
);
