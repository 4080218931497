import styled from 'styled-components';

export const MenuBar = styled.ul.attrs(() => ({
  role: 'menubar',
  'aria-label': 'Sharesight Navigation Menu',
}))`
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;
