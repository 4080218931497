import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

/**
 * Some styles provided by rc-tooltip, which we override a bit.
 * Provides important positioning styling!
 */
import 'rc-tooltip/assets/bootstrap_white.css';
import { tooltipStyles } from 'components/Tooltip/Tooltip.styled';
import { appearanceMixin } from 'components/Button/appearanceMixin';
import { getDimension } from './helpers/getDimension';

export const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: 'Financier';
  src: url(/fonts/financier-display-semibold.ttf) format('truetype'), 
  url(/fonts/financier-display-semibold.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url(/fonts/NunitoSans-Regular.ttf);
  font-display: swap;
}

  ${normalize}

  *, ::before, ::after {
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; // 1rem = 10px
    -webkit-font-smoothing: antialiased;
    font-family: 'Nunito Sans', 'Helvetica', sans-serif;
  }

  html, body {
    box-sizing: border-box;
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    color: ${props => props.theme.colors.black};
    font-weight: ${props => props.theme.fontWeight.normal};
    font-size: ${props => props.theme.fontSize.sm};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: ${props => props.theme.fontSize.xxs};
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    margin-bottom: ${getDimension('size4')};
    font-weight: ${props => props.theme.fontWeight.normal}; // override below

  }

  h1, h2, h3 {
    font-family: ${props => props.theme.fontFamily.financier};
    font-weight: ${props => props.theme.fontWeight.semiBold}; 
    margin-bottom: ${getDimension('size8')};
  }

  h4, h5, h6 {
    font-family: ${props => props.theme.fontFamily.nunitoSans};
  }

  h1 { 
    font-size: ${props => props.theme.fontSize.xxl}; 
    line-height: ${props => props.theme.lineHeight.xxl}; 
  }
  h2 { 
    font-size: ${props => props.theme.fontSize.xl}; 
    line-height: ${props => props.theme.lineHeight.xl}; 
  }
  h3 { 
    font-size: ${props => props.theme.fontSize.lg}; 
    line-height: ${props => props.theme.lineHeight.lg}; 
  }
  h4 { 
    font-weight: ${props => props.theme.fontWeight.bold}; 
    font-size: ${props => props.theme.fontSize.md}; 
    line-height: ${props => props.theme.lineHeight.md}; 
  }
  h5 { 
    font-weight: ${props => props.theme.fontWeight.bold}; 
    font-size: ${props => props.theme.fontSize.xs}; 
    line-height: ${props => props.theme.lineHeight.xs}; 
  }
  h6 { 
    font-weight: ${props => props.theme.fontWeight.normal};
    font-size: ${props => props.theme.fontSize.sm}; 
    line-height: ${props => props.theme.lineHeight.sm}; 
  }

  p {
    font-size: ${props => props.theme.fontSize.sm};
    line-height:  ${props => props.theme.lineHeight.sm};
    font-weight:  ${props => props.theme.fontWeight.normal};
    margin: 0;
    margin-bottom: ${getDimension('size4')};
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: text-decoration-offset 300ms, text-decoration-thickness 300ms; // NOTE: Not really browser supported…

    &:hover,
    &:focus,
    &:focus-within {
      text-underline-offset: 1px;
      text-decoration-thickness: 2px;
      color: ${props => props.theme.colors.primary};
      transition: color 250ms;

    }

    ${appearanceMixin(
      {
        appearance: 'link',
        containerBackground: 'positive',
      },
      true
    )}

    svg {
      vertical-align: middle;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    h1 { 
      font-size: ${props => props.theme.fontSize.xl}; 
      line-height: ${props => props.theme.lineHeight.xl}; 
    }
    h2 { 
      font-size: ${props => props.theme.fontSize.lg}; 
      line-height: ${props => props.theme.lineHeight.lg}; 
    }
    h3 { 
      font-size: ${props => props.theme.fontSize.md}; 
      line-height: ${props => props.theme.lineHeight.md}; 
    }
    h4 { 
      font-weight: ${props => props.theme.fontWeight.bold}; 
      font-size: ${props => props.theme.fontSize.xs}; 
      line-height: ${props => props.theme.lineHeight.xs}; 
    }
    h5 { 
      font-weight: ${props => props.theme.fontWeight.normal};
      font-size: ${props => props.theme.fontSize.sm}; 
      line-height: ${props => props.theme.lineHeight.sm}; 
    }
    h6 { 
      font-weight: ${props => props.theme.fontWeight.normal};
      font-size: ${props => props.theme.fontSize.xs}; 
      line-height: ${props => props.theme.lineHeight.xxs}; 
    }
    p { 
      font-size: ${props => props.theme.fontSize.xxs};
      line-height:  ${props => props.theme.lineHeight.xxs};
    }
  }

  ${tooltipStyles}
`;
