import { isValidPath } from './isValidPath';

const cleanPath = (path: string) => {
  // Wrap in slashes
  let normalized = `/${path}/`;

  // Convert duplicate slashes into one
  normalized = normalized.replace(/[/]+/g, '/');

  /*
   * If we have an anchor or query params or an extension, strip the trailing slash.
   * Eg. `file.json`, `file?query=true`, or `file#anchor` all get stripped.
   */
  if (/[\.\#\?]/.test(normalized)) {
    normalized = normalized.replace(/[/]+$/, '');
  }

  if (!isValidPath(normalized)) {
    throw new Error(`Invalid path sent to cleanupPath: '${path}' => '${normalized}'.`);
  }

  return normalized;
};

interface NormalizePathParams {
  path: string;
  siteUrl?: string;
}

export const normalizePath = ({ path, siteUrl }: NormalizePathParams) => {
  let strippedPath = path;

  // If we have an exact siteUrl match, strip it…we just want the path.
  if (siteUrl && strippedPath.startsWith(siteUrl)) strippedPath = strippedPath.replace(siteUrl, '');

  return cleanPath(strippedPath);
};

const trimPathTail = (path: string) => {
  if (!/[\.\#\?]/.test(path)) {
    return path;
  }

  // index of first occurence of '.', '#', or '?'
  const index = path.indexOf(path.match(/[\.\#\?]/)![0]);

  // trim and append '/'
  let trimmed = `${path.substring(0, index)}/`;

  // reduce possible multiple trailing slashes to one
  trimmed = trimmed.replace(/[/]+/g, '/');
  return trimmed;
};

/**
 * Returns a normalized path, where anchor/query-params/extensions have been trimmed.
 *
 * e.g.
 * https://www.sharesight.com/dividend-tracker/#fake-anchor
 * becomes
 * /dividend-tracker/
 *
 * This should be used instead of `normalizePath` when checking if a path is remote or not.
 * Otherwise urls like https://www.sharesight.com/dividend-tracker/#fake-anchor are identified as remote.
 */
export const normalizeAndTrimPath = (params: NormalizePathParams) =>
  trimPathTail(normalizePath(params));
