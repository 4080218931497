import styled, { css } from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

import type { Color, Size } from 'styled/types';

type MarginSize = 'auto' | Size;

export interface BoxProps {
  padding?: Size;
  paddingTop?: Size;
  paddingRight?: Size;
  paddingBottom?: Size;
  paddingLeft?: Size;

  margin?: MarginSize;
  marginTop?: MarginSize;
  marginRight?: MarginSize;
  marginBottom?: MarginSize;
  marginLeft?: MarginSize;
  width?: string;
  maxWidth?: string;
  height?: string;

  borderRadius?: Size;
  background?: Extract<
    Color,
    | 'cream'
    | 'secondary'
    | 'white'
    | 'navyBlue'
    | 'blue'
    | 'navyBlueHighlight'
    | 'orangeHighlight'
    | 'primaryHighlight'
    | 'yellowHighlight'
  >;
}

const marginMixin = (value: MarginSize) => {
  if (value === 'auto') {
    return 'auto';
  }
  return getDimension(`size${value}`);
};

export const Box = styled.div<BoxProps>`
  ${({
    padding = 0,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,

    margin = 0,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    maxWidth,
    width = '100%',
    height,

    // styled
    borderRadius,
    background,
  }) => css`
    padding-top: ${getDimension(`size${paddingTop || padding}`)};
    padding-right: ${getDimension(`size${paddingRight || padding}`)};
    padding-bottom: ${getDimension(`size${paddingBottom || padding}`)};
    padding-left: ${getDimension(`size${paddingLeft || padding}`)};

    margin-top: ${marginMixin(marginTop || margin)};
    margin-right: ${marginMixin(marginRight || margin)};
    margin-bottom: ${marginMixin(marginBottom || margin)};
    margin-left: ${marginMixin(marginLeft || margin)};

    width: ${width};

    ${height &&
    css`
      height: ${height};
    `}

    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

    ${borderRadius &&
    css`
      border-radius: ${getDimension(`size${borderRadius}`)};
    `}

    ${background &&
    css`
      background-color: ${({ theme }) => theme.colors[background]};
    `}
  `}
`;
