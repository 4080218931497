/*
 * Example from gatsby-plugin-portal
 * https://www.gatsbyjs.com/plugins/gatsby-plugin-portal/?=gatsby-plugin-portal#gatsby-gotcha---document-is-undefined
 */

import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import type { HTMLAttributes, FC } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Portal: FC<Props> = ({ children }) => {
  const isSSR = typeof document === 'undefined';
  const root = useRef(isSSR ? null : document.getElementById('portal'));
  const element = useRef(isSSR ? null : document.createElement('div'));

  // If we have no portal element, mount it!
  useEffect(() => {
    if (!root.current) {
      root.current = document.createElement('div');
      root.current.setAttribute('id', 'portal');
      document.body.appendChild(root.current);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const currentPortal = root.current;
    const currentElement = element.current;

    if (currentElement) {
      currentPortal?.appendChild(currentElement);

      return () => {
        currentPortal?.removeChild(currentElement);
      };
    }
  }, []);

  if (element.current) return createPortal(children, element.current);
  return null;
};
