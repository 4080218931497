import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { normalizePath } from 'helpers/url';

import { findNodePages } from './findNodePages';
import { useSocialImage } from './useSocialImage';

import type { Locale, LocaleLang } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { ContentfulPage } from './types';

interface PartnerPageRaw {
  contentfulId: string;
  localeLang: LocaleLang;
  id: string;
  urlSlug: string;
  name: string;
  shortDescription: string;
}

interface PartnerPageGraphQlResponse {
  allContentfulPartner: {
    nodes: PartnerPageRaw[];
  };
}

const query = graphql`
  query usePartnerPageQuery {
    allContentfulPartner(filter: { node_locale: { eq: "en" } }) {
      nodes {
        contentfulId: contentful_id
        localeLang: node_locale
        id
        urlSlug
        name
        shortDescription
      }
      distinct(field: urlSlug)
    }
  }
`;

/** Get a ContentfulPage data object for a partner page. */
export const usePartnerPage = ({
  path,
  locale,
}: {
  path: string;
  locale: Locale;
}): ContentfulPage | undefined => {
  const { appendTitle, socialAppendTitle } = useContentfulLocale(locale);

  const { defaultLocale } = useLocalizationContext();
  const { allContentfulPartner }: PartnerPageGraphQlResponse = useStaticQuery(query);

  const { localePage, globalPage } = useMemo(() => {
    const partnerNodes = allContentfulPartner.nodes.filter(p => {
      const partnerPath = normalizePath({ path: `/partners/${p.urlSlug}/` });

      return normalizePath({ path }) === partnerPath;
    });

    if (!partnerNodes) return {};

    return findNodePages<PartnerPageRaw>({
      nodes: partnerNodes,
      defaultLocale,
      locale,
      debug: `Contentful::Partner for path='${path}'`,
    });
  }, [allContentfulPartner, defaultLocale, locale, path]);

  const socialImage = useSocialImage();

  if (!localePage || !globalPage) return undefined;

  return {
    // A partner has none of these:
    cards: [],
    accordionItems: [],
    sections: [],
    translations: {},

    seoProps: {
      noIndex: false, // always index partners
      title: `${localePage.name} | ${appendTitle}`,
      rawTitle: localePage.name, // passed around so we can use it for some labels or alt/title attributes
      description: localePage.shortDescription,
      socialTitle: `${globalPage.name} | ${socialAppendTitle}`,
      socialImage,
      socialDescription: globalPage.shortDescription,
    },
  };
};
