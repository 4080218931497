import { BaseButton } from './BaseButton';
import type { ButtonProps } from './types';

export const Button = ({
  children,
  onClick,
  title,
  'aria-label': ariaLabel,
  ...props
}: ButtonProps) => (
  <BaseButton
    {...props}
    // specific to the Button
    onClick={onClick}
    title={title}
    type="button"
    as="button"
    aria-label={ariaLabel}
  >
    {children}
  </BaseButton>
);
