import type { Schemas } from './types';

export const schemas: Schemas = {
  '/': [
    {
      key: 'index-organization',
      schema: {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        name: 'Sharesight',
        url: 'https://www.sharesight.com/',
        logo: {
          url: 'https://dhln4w2iqv5o4.cloudfront.net/assets/logo-colour-default-b07a14e9f91e896b747ebc234cfc964bbcc2dfe66785c080485dd1d532c82b6f.svg',
          '@type': 'ImageObject',
        },
        description:
          'Sharesight is a powerful portfolio tracking software that lets you check your investments in one place with award-winning performance, dividend tracking and tax reporting.',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.4',
          ratingCount: '302',
          reviewCount: '302',
          bestRating: '5',
          worstRating: '1',
        },
        award: 'Sharesight named a finalist in the 2021 Finnies Awards',
      },
    },
  ],
  '/features/': [
    {
      key: 'features-product',
      schema: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: 'Sharesight portfolio tracker',
        image:
          'https://www.sharesight.com/static/0bd3aa5cb330913f641eb23d3d17ed61/b2459/portfolio-hero.webp',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.4',
          ratingCount: '302',
        },
      },
    },
    {
      key: 'features-video',
      schema: {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: 'Sharesight - Be the smarter investor',
        description:
          "This video is an overview of how Sharesight's portfolio tracker app works. It summarises how to import your investment trading history, track performance, automate dividend tracking and calculate tax.",
        thumbnailUrl: 'https://img.youtube.com/vi/IIgxxIfHOis/sddefault.jpg',
        uploadDate: '2022-08-16',
        duration: 'PT10M14S',
        contentUrl: 'https://www.sharesight.com/features/',
        embedUrl: 'https://youtu.be/IIgxxIfHOis',
      },
    },
  ],
  '/dividend-tracker/': [
    {
      key: 'dividend-product',
      schema: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: 'Sharesight portfolio tracker',
        image: 'https://www.sharesight.com/static/eb733d073e662230784189978e1435d3/451c3/hero.webp',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.4',
          ratingCount: '302',
        },
      },
    },
  ],
  '/investment-portfolio-performance/': [
    {
      key: 'performance-product',
      schema: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: 'Sharesight portfolio tracker',
        image: 'https://www.sharesight.com/static/caf1c59e93f6c8119c027fae31c91603/d05cf/hero.webp',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.4',
          ratingCount: '302',
        },
      },
    },
  ],
  '/investment-portfolio-tax/': [
    {
      key: 'tax-product',
      schema: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: 'Sharesight portfolio tracker',
        image: 'https://www.sharesight.com/static/075a7bb561357f7fe093d1b3e010722f/e7798/hero.webp',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.4',
          ratingCount: '302',
        },
      },
    },
    {
      key: 'tax-faq-au',
      locales: ['au'],
      schema: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: 'What is capital gains tax?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Capital gains tax is a tariff that investors pay on the profit made from selling an investment. Capital gains are included in investment income tax in Australia, and there are different levels of taxation depending on how long the investor holds the investment before selling. Specifically, long-term capital gains (for stocks held longer than a year) are taxed at a 50% discount, which means tax is only paid on half of the net capital gain. To learn more about the tax implications of capital gains, please refer to the ATO rules.',
            },
          },
          {
            '@type': 'Question',
            name: 'How to calculate capital gains tax on investments?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Investors can calculate the capital gains tax on shares and other investments they have sold by calculating the difference between the value of the purchase price (adjusted to include any transaction fees) and the current market value at the point of sale. However, this can be time-consuming and subject to error, especially for investors with a lot of trades. Australian investors can automatically calculate the CGT on their investments with Sharesight’s Capital Gains Tax Report, which provides investors with a breakdown of their short-term and long-term capital gains, as well as capital losses. For investments that have been entirely sold out of the portfolio, Sharesight’s Sold Securities Report will also display the capital, dividend and currency gains associated with those investments, allowing investors to calculate their average cost base.',
            },
          },
          {
            '@type': 'Question',
            name: 'Do I need to report capital gains on taxes?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'If you incur capital gains or losses on the sale of an investment, then you will need to report your capital gains/losses in your Australian income tax return. For specific advice on reporting capital gains, please refer to the ATO rules.',
            },
          },
          {
            '@type': 'Question',
            name: 'Are dividends taxable income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Yes. Dividends are considered taxable income in Australia and must be reported in your income tax return. The rate at which dividends are taxed, however, depends on a variety of factors such as whether the dividend-paying assets are held in the form of a company, trust or Self-Managed Super Fund (SMSF), and whether the dividends have any franking credits attached. For more detailed information on reporting dividend income in your tax return, please refer to the ATO rules.',
            },
          },
          {
            '@type': 'Question',
            name: 'Are ETF distributions taxable income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Yes. ETF distribution income is considered taxable income in Australia and must be reported in your income tax return. ETF distributions can include various forms of investment income, such as dividends, franking credits, realised capital gains, interest and foreign income, which can create tax complexity. Taxation can also become more complex if the distributions have been paid out by an ETF that is structured as an Attribution Managed Investment Trust (AMIT), which handles the treatment of tax-deferred income differently than Managed Investment Trusts (MIT). Sharesight makes tax time easier for investors by automatically tracking dividends and distributions, including AMIT component breakdowns.',
            },
          },
          {
            '@type': 'Question',
            name: 'How do I calculate taxable income from dividends?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: "Done manually, calculating taxable income from dividends can be a tedious process, requiring investors to sort through a combination of paper statements and digital statements via multiple share registries. However, Sharesight automates this process by automatically tracking dividends and distributions. Sharesight also makes it easy for investors to calculate the taxable income on their dividends by using Sharesight’s Taxable Income Report. This report displays a portfolio's dividends and interest payments, along with the relevant totals investors need to complete their tax return.",
            },
          },
          {
            '@type': 'Question',
            name: 'What are unrealised capital gains?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Unrealised capital gains occur when the value of an investment in an investor’s portfolio has increased, however the investor has not sold the investment. This means the gains have not been “realised”. Unrealised capital gains are useful information for investors who want to use tax loss selling (or tax loss harvesting) to minimise their net capital gains during the financial year for tax purposes.',
            },
          },
          {
            '@type': 'Question',
            name: 'How to calculate unrealised gains for tax harvesting?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Australian investors who wish to see a summary of their unrealised capital gains (or losses) for tax loss harvesting purposes can use Sharesight’s Unrealised Capital Gains Tax Report . This report divides unrealised CGT into short-term capital gains, long-term capital gains and capital losses, and allows investors to change their sale allocation method as needed.',
            },
          },
          {
            '@type': 'Question',
            name: 'Who is Sharesight’s Taxable Income Report suitable for?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sharesight’s Taxable Income Report is designed for investors looking for a complete list of the dividends and interest they earned over the time period of their choice. It is a useful tool for investors who need to prepare their tax return, including investors who invest in foreign markets and need a breakdown of their local and foreign dividend income.',
            },
          },
        ],
      },
    },
    {
      key: 'tax-faq-nz',
      locales: ['nz'],
      schema: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: 'Are dividends taxable income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Yes. Dividends are considered taxable income in New Zealand and must be reported in your income tax return. The rate at which dividends are taxed, however, depends on a variety of factors such as whether the dividends have imputation credits, whether resident withholding tax (RWT) has been applied, or whether the dividend has been paid by an overseas company. For more detailed information on reporting dividend income in your tax return, please refer to the IRD rules.',
            },
          },
          {
            '@type': 'Question',
            name: 'Are ETF distributions taxable income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Yes. ETF distribution income is considered taxable income in New Zealand and must be reported in your income tax return. ETFs are classified as listed portfolio investment entities (PIEs), and any income received will be taxed at the investor’s Prescribed Investor Rate (PIR). For more information on ETFs and tax, please refer to the IRD rules.',
            },
          },
          {
            '@type': 'Question',
            name: 'How to calculate dividend income for tax returns?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: "Done manually, calculating taxable income from dividends can be a tedious process, requiring investors to sort through a combination of paper statements and digital statements via multiple share registries. However, Sharesight automates this process by automatically tracking dividends and distributions. Sharesight also makes it easy for investors to calculate the taxable income on their dividends by using Sharesight’s Taxable Income Report. This report displays a portfolio's dividends and interest payments, along with the relevant totals investors need to complete their tax return.",
            },
          },
          {
            '@type': 'Question',
            name: 'Is capital gains tax payable on investments in New Zealand?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'No, there is no capital gains tax payable on investments in New Zealand.',
            },
          },
          {
            '@type': 'Question',
            name: 'How to calculate taxable gains on investments as a trader?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'While there is no general capital gains tax in New Zealand, tax on gains may apply to investors trading shares when they purchase investments with the intention to sell them at a profit. In these situations, the investor may be classed as a “trader” by Inland Revenue. Sharesight makes it easy for share traders to calculate their taxable gains with the Traders Tax Report, which provides a summary of the investor’s capital gains and losses, including the option to change the sale allocation as needed. For more detailed information about taxable gains for traders, please refer to IRD rules.',
            },
          },
          {
            '@type': 'Question',
            name: 'What are foreign investment fund (FIF) taxes?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Foreign investment fund (FIF) taxes apply to New Zealand residents with certain offshore investments, such as foreign companies, unit trusts, superannuation schemes or life insurance policies. FIF income is considered taxable income, however there are various exemptions that will affect the specific tax implications for investors, as well as a variety of methods that are used to calculate FIF income. Please refer to the IRD for more information.',
            },
          },
          {
            '@type': 'Question',
            name: 'How to calculate foreign investment fund income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'While Inland Revenue supplies an online calculator to determine whether you have FIF income, these calculations can be quite complex. For a quick and easy way to calculate FIF income, NZ investors on a Sharesight Expert or Pro plan can run their own FIF Report in just a few clicks.',
            },
          },
          {
            '@type': 'Question',
            name: 'Who is Sharesight’s Taxable Income Report suitable for?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sharesight’s Taxable Income Report is designed for investors looking for a complete list of the dividends and interest they earned over the time period of their choice. It is a useful tool for investors who need to prepare their tax return, including investors who invest in foreign markets and need a breakdown of their local and foreign dividend income.',
            },
          },
        ],
      },
    },
    {
      key: 'tax-faq-ca',
      locales: ['ca'],
      schema: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: 'What is capital gains tax?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Capital gains tax (CGT) is a tariff that investors pay on the profit made from selling an investment. In Canada, 50% of the value of any capital gains are taxable, and will be added to your assessable income. Therefore, the amount of tax you pay on capital gains will vary depending on what other sources of income you have and how much you earn. Please refer to the CRA for more information.',
            },
          },
          {
            '@type': 'Question',
            name: 'How to calculate capital gains tax on investments?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Investors can calculate the capital gains on investments they have sold by calculating the difference between the value of the purchase price (adjusted to include any transaction fees) and the current market value at the point of sale. However, this can be time-consuming and subject to error, especially for investors with a lot of trades. Canadian investors can automatically calculate the CGT on their investments with Sharesight’s Capital Gains Tax Report, which provides investors with a breakdown of their short-term and long-term capital gains, as well as capital losses.',
            },
          },
          {
            '@type': 'Question',
            name: 'Do I need to report capital gains on taxes?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'If you incur a capital gain (or allowable capital loss) on the sale of an investment, then you will need to report your capital gains/losses in your Canadian income tax return. The exception is for capital gains earned in tax-sheltered accounts such as an RRSP or RRIF (unless a withdrawal is made) or a TFSA. For specific advice on reporting capital gains/losses and whether you need to pay capital gains tax, please refer to the CRA rules.',
            },
          },
          {
            '@type': 'Question',
            name: 'Are dividends taxable income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Yes. Dividends are considered taxable income in Canada and must be reported in your income tax return. However, investors may be eligible for a dividend tax credit (for Canadian investments held outside an RRSP, RRIF or TFSA), which will reduce their effective tax rate. Please refer to the CRA for more information about dividend tax credits.',
            },
          },
          {
            '@type': 'Question',
            name: 'Are ETF distributions taxable income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Yes. ETF distribution income is considered taxable income in Canada and must be reported in your income tax return. ETF distributions can include various forms of investment income, such as dividends, capital gains, return of capital, reinvested distributions and foreign income, which can create tax complexity. Canadian investors who hold US ETFs may also find their dividends are subject to withholding tax, which can create two levels of tax consideration. Sharesight makes tax time easier for investors by automatically tracking dividends and distributions, including foreign withholding tax components.',
            },
          },
          {
            '@type': 'Question',
            name: 'How do I calculate taxable income from dividends?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: "Done manually, calculating taxable income from dividends can be a tedious process, requiring investors to sort through a combination of paper statements and digital statements via multiple share registries. However, Sharesight automates this process by automatically tracking dividends and distributions. Sharesight also makes it easy for investors to calculate the taxable income on their dividends by using Sharesight’s Taxable Income Report. This report displays a portfolio's dividends and interest payments, along with the relevant totals investors need to complete their tax return.",
            },
          },
          {
            '@type': 'Question',
            name: 'What are unrealised capital gains?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Unrealized capital gains occur when the value of an investment in an investor’s portfolio has increased, however the investor has not sold the investment. This means the gains have not been “realized”. Unrealized capital gains are useful information for investors who want to use tax loss selling (or tax loss harvesting) to minimize their net capital gains during the financial year for tax purposes.',
            },
          },
          {
            '@type': 'Question',
            name: 'Who is Sharesight’s Taxable Income Report suitable for?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sharesight’s Taxable Income Report is designed for investors looking for a complete list of the dividends and interest they earned over the time period of their choice. It is a useful tool for investors who need to prepare their tax return, including investors who invest in foreign markets and need a breakdown of their local and foreign dividend income.',
            },
          },
        ],
      },
    },
    {
      key: 'tax-faq-us-uk-global',
      locales: ['us', 'uk', 'global'],
      schema: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: 'What is capital gains tax?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Capital gains tax is a tariff that investors pay on the profit made from selling an investment. The specific tax implications of capital gains differ depending on the tax residency of the investor, however in some countries such as the US, there are different levels of taxation depending on how long the investor holds the investment before selling. For example, in the US, short-term capital gains (for stocks held for less than a year) are taxed at ordinary income rates; however long-term capital gains (for stocks held for a year or longer) are taxed at rates of 0%, 15% and 20%, depending on the investor’s income, which is a discounted rate compared to ordinary income tax rates. In the UK, however, capital gains tax is only paid if the investor has exceeded the Annual Exempt Amount (AMA), and the tax rate for capital gains on shares ranges from 10% to 20% depending on the investor’s tax bracket.',
            },
          },
          {
            '@type': 'Question',
            name: 'How to calculate capital gains tax on investments?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Investors can calculate the capital gains on investments they have sold by calculating the difference between the value of the purchase price (adjusted to include any transaction fees) and the current market value at the point of sale. The tax implications resulting from this will differ depending on the tax residency of the investor, so please refer to your local tax authority or tax accountant for information on capital gains tax.',
            },
          },
          {
            '@type': 'Question',
            name: 'Do I need to report capital gains on taxes?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'If you incur capital gains or losses on the sale of an investment, then depending on your tax residency, you may need to disclose the capital gain/loss in your tax form. For specific advice on reporting capital gains, please refer to your local tax authority or tax accountant.',
            },
          },
          {
            '@type': 'Question',
            name: 'Are dividends taxable income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Depending on your tax residency, dividends may be considered taxable income. In some countries such as the US, dividend income is generally taxable (with the exception of 401ks which are typically tax-deferred until withdrawal); while in the UK investors receive an allowance for tax-free dividends, and will need to pay tax if their dividend payments exceed the threshold (excluding dividends paid into an ISA). The tax implications of dividends are different in each tax residency, so for specific information on reporting dividend income in your tax return please consult your local tax authority or tax accountant.',
            },
          },
          {
            '@type': 'Question',
            name: 'Are ETF distributions taxable income?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'In many countries, ETF distribution income is considered taxable income, just the same as dividends paid by individual stocks. However, this is often dependent on certain factors such as how long the investor has held the ETF or whether the ETF consists entirely of stocks. The specific taxation details will depend on the investor’s tax residency.',
            },
          },
          {
            '@type': 'Question',
            name: 'How do I calculate taxable income from dividends?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: "Done manually, calculating taxable income from dividends can be a tedious process, requiring investors to sort through a combination of paper statements and digital statements via multiple share registries. However, Sharesight automates this process by automatically tracking dividends and distributions. Sharesight also makes it easy for investors to calculate the taxable income on their dividends by using Sharesight’s Taxable Income Report. This report displays a portfolio's dividends and interest payments, along with the relevant totals investors need to complete their tax return.",
            },
          },
          {
            '@type': 'Question',
            name: 'What are unrealised capital gains?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Unrealized capital gains occur when the value of an investment in an investor’s portfolio has increased, however the investor has not sold the investment. This means the gains have not been “realized”. Unrealized capital gains are useful information for investors who want to use tax loss selling (or tax loss harvesting) to minimize their net capital gains during the financial year for tax purposes.',
            },
          },
          {
            '@type': 'Question',
            name: 'Who is Sharesight’s Taxable Income Report suitable for?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Sharesight’s Taxable Income Report is designed for investors looking for a complete list of the dividends and interest they earned over the time period of their choice. It is a useful tool for investors who need to prepare their tax return, including investors who invest in foreign markets and need a breakdown of their local and foreign dividend income.',
            },
          },
        ],
      },
    },
  ],
};
