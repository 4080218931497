import { useCallback } from 'react';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import ReactGA from 'react-ga4';

import { isProPath } from 'helpers/url';
import { AppLink } from './AppLink';

import type { FC } from 'react';
import type { AppLinkProps } from './AppLink';

interface SignupLinkProps extends Omit<AppLinkProps, 'onClick' | 'to' | 'openInNewTab'> {
  to?: string;
}

export const SignupLink: FC<SignupLinkProps> = ({
  children,
  title = 'Sign up to Sharesight for free',
  appearance = 'primary',
  containerBackground = 'positive',
  color = undefined,
  asButton = false,
  ...props
}) => {
  const { basePath } = useLocalizationContext();

  const isPro = isProPath({ basePath });

  const signupTitle = isPro ? 'Start your free trial' : title;
  const to = isPro ? '/professional_signup' : '/signup';
  const signupChildren = isPro ? 'Start your free trial' : 'Sign up for free';

  const onClick = useCallback(() => {
    if (!isPro) ReactGA.event('clicked_retail_signup');
  }, [isPro]);

  return (
    <AppLink
      openInNewTab={false}
      {...props}
      color={color}
      appearance={appearance}
      to={to}
      title={signupTitle}
      asButton={asButton}
      containerBackground={containerBackground}
      onClick={onClick}
    >
      {children || signupChildren}
    </AppLink>
  );
};
