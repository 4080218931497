import styled, { css } from 'styled-components';
import { CaretRight } from 'phosphor-react';

import { getReadableColor } from 'styled/helpers/colors';
import { getDimension } from 'styled/helpers/getDimension';

import { GatsbyLink } from 'components/Links';

import type { Theme } from 'styled/types';

export const LinkHighlight = styled.span<{ bold?: boolean; theme?: Theme }>`
  padding: ${getDimension('size2')};
  border-radius: ${getDimension('size2')};

  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;

  will-change: background-color;
  transition: 200ms background-color;

  ${props =>
    props.bold &&
    css`
      font-weight: ${props.theme.fontWeight.bold};
    `}
`;

export const StyledCaretRight = styled(CaretRight)`
  will-change: opacity;
  transition: 200ms opacity;
  opacity: 0;
  margin-left: 1ch;
`;

export const Link = styled(GatsbyLink)`
  font-size: ${props => props.theme.fontSize.xxs};
  display: block;
  text-decoration: none;

  padding: 0.3rem 0;

  color: inherit;
  font-weight: bold;

  &:hover,
  &:focus,
  &:focus-within {
    ${LinkHighlight} {
      ${props => {
        const background = props.theme.colors.yellow;
        const color = getReadableColor({ hex: background, requiredContrast: 'AA' });

        return css`
          background: ${background};
          color: ${color};
        `;
      }}
    }

    ${StyledCaretRight} {
      opacity: 1;
    }
  }
`;
