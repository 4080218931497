import type { MenuLink } from '../types';

export const featuresLinks: MenuLink[] = [
  { label: 'Features', path: 'features', corePage: true },
  { label: 'Tax reporting', path: 'investment-portfolio-tax' },
  { label: 'Performance', path: 'investment-portfolio-performance' },
  { label: 'Dividends', path: 'dividend-tracker' },
  {
    label: 'Supported brokers',
    title: 'Supported brokers',
    path: 'partners',
  },
  {
    label: 'Supported markets',
    title: `Supported markets`,
    path: 'supported-stock-exchanges-managed-funds-mutual-funds',
    shouldAppendTitle: true, // custom title as it's not a local page
  },
  {
    label: 'Supported software',
    title: `Supported software`,
    path: 'partners',
    shouldAppendTitle: true, // custom title as it's not a local page
  },
  {
    label: 'Data security',
    title: `Data security`,
    helpPath: 'how-sharesight-protects-your-data',
  },
];
