import { css } from 'styled-components';

import { getScaledDimension } from 'styled/helpers/getScaledDimension';

import { getDimension } from './getDimension';
import type { Size, Theme } from 'styled/types';

interface WithPadding {
  /**
   * padding=0 turns this off entirely;
   * padding='sm' (etc) sizes the padding.
   * padding=undefined = some padding;
   */
  padding?: Size;
  scaleMobilePadding?: boolean;
  bottomPadding?: Size;
  theme?: Theme;
}

export const bodyPaddingMixin = ({
  padding,
  scaleMobilePadding,
  bottomPadding,
  theme,
}: WithPadding) =>
  padding !== 0 &&
  css`
    padding: ${getDimension(`size${padding || 0}`)({ theme })};
    padding-left: ${getDimension('size8')({ theme })};
    padding-right: ${getDimension('size8')({ theme })};

    ${bottomPadding &&
    css`
      padding-bottom: ${getDimension(`size${bottomPadding}`)({ theme })};
    `}

    ${theme?.breakpoints.down('md')} {
      padding-left: ${getDimension('size4')({ theme })};
      padding-right: ${getDimension('size4')({ theme })};
    }

    ${scaleMobilePadding &&
    css`
      /** Ideally used for padding xl and xxl 
      * This basically scales padding: 100px => 66.7px => 33.3px => 11px… */
      ${theme?.breakpoints.down('lg')} {
        padding-top: ${getScaledDimension({ size: padding || 0, scale: 0.667, theme })};
        padding-bottom: ${getScaledDimension({ size: padding || 0, scale: 0.667, theme })};
      }

      ${theme?.breakpoints.down('md')} {
        padding-top: ${getScaledDimension({ size: padding || 0, scale: 0.5, theme })};
        padding-bottom: ${getScaledDimension({ size: padding || 0, scale: 0.5, theme })};
      }
    `}
  `;
