import { useStaticQuery, graphql } from 'gatsby';
import { useMemo } from 'react';
import { normalizePath } from 'helpers/url';
import { matchesWildcard } from 'helpers/matchesWildcard';
import { useSocialImage } from './useSocialImage';
import type { BlogCategoriesGraphQLResponse, ContentfulPage } from './types';

const query = graphql`
  query useBlogCategoryPageQuery {
    allContentfulCategory {
      nodes {
        contentfulId: contentful_id
        id
        name {
          name
        }
        urlSlug
        categoryDescription
      }
    }
  }
`;

export const useBlogCategoryPage = ({ path }: { path: string }): ContentfulPage | undefined => {
  const { allContentfulCategory }: BlogCategoriesGraphQLResponse = useStaticQuery(query);

  const blogCategory = useMemo(
    () =>
      allContentfulCategory.nodes
        .filter(category => !!category?.name?.name)
        .find(category => {
          const comparePath = normalizePath({ path });
          const categoryPath = normalizePath({ path: `/blog/${category.urlSlug}` });

          return (
            comparePath === categoryPath || !!matchesWildcard(comparePath, `${categoryPath}pages/*`)
          );
        }),
    [allContentfulCategory, path]
  );

  const socialImage = useSocialImage();

  if (!blogCategory?.name) return undefined;

  const title = blogCategory.name!.name;
  const description = blogCategory.categoryDescription;

  return {
    // A blog post has none of these:
    cards: [],
    accordionItems: [],
    sections: [],
    translations: {},

    seoProps: {
      noIndex: false, // always index blogs
      title,
      rawTitle: title,
      description,
      socialTitle: title,
      socialImage, // this should use the generic socialImage coming from useSocialImage
      socialDescription: description,
    },
  };
};
