import { css } from 'styled-components';
import { darken } from 'polished';

import { getReadableColor } from './getReadableColor';

import type { GetReadableColorOptions } from './getReadableColor';
import type { Color, Highlight, Theme } from 'styled/types';

interface MixinProps {
  theme: Theme;
  source: {
    [key in Color]?: string;
  };
  backgroundColor?: Color;
  textColor?: Color;
  requiredContrast?: GetReadableColorOptions['requiredContrast'];
  onEvent: boolean;
  darkenValue?: number;
}

const colorMixin = ({
  theme,
  source,
  backgroundColor,
  textColor,
  requiredContrast = 'AA',
  onEvent,
  darkenValue = 0.075,
}: MixinProps) => {
  const background = backgroundColor ? source[backgroundColor] : undefined;

  if (!background || background === 'transparent') {
    return css`
      background: transparent;

      ${textColor &&
      css`
        color: ${theme.colors[textColor]};
      `}
    `;
  }

  const backgroundHover = darken(darkenValue, background);
  let color = getReadableColor({ hex: background, requiredContrast });
  if (textColor) color = theme.colors[textColor];

  return css`
    background-color: ${background};
    color: ${color};

    ${onEvent &&
    css`
      &:hover,
      &:focus,
      &:focus-within {
        background-color: ${backgroundHover};
        color: ${color};
      }
    `}
  `;
};

interface ColorMixin {
  theme: Theme;
  color?: Color;
  textColor?: Color;
  requiredContrast?: GetReadableColorOptions['requiredContrast'];
}

export const solidColorMixin = (
  { theme, color, textColor, requiredContrast }: ColorMixin,
  onEvent: boolean = false
) =>
  colorMixin({
    theme,
    source: theme.colors,
    backgroundColor: color,
    textColor,
    requiredContrast,
    onEvent,
  });

interface BackgroundMixin {
  theme: Theme;
  background?: Color;
  textColor?: Color;
  requiredContrast?: GetReadableColorOptions['requiredContrast'];
}

export const softColorMixin = (
  { theme, background, textColor, requiredContrast }: BackgroundMixin,
  onEvent: boolean = false
) =>
  colorMixin({
    theme,
    source: theme.colors,
    backgroundColor: background,
    textColor,
    requiredContrast,
    onEvent,
  });

interface HighlightMixin {
  theme: Theme;
  highlight?: Highlight;
  textColor?: Color;
  requiredContrast?: GetReadableColorOptions['requiredContrast'];
}

export const fadedColorMixin = (
  { theme, highlight, textColor, requiredContrast }: HighlightMixin,
  onEvent: boolean = false
) =>
  colorMixin({
    theme,
    source: theme.colors,
    backgroundColor: highlight,
    textColor,
    requiredContrast,
    onEvent,
    darkenValue: 0.15,
  });

type ColorFromPropsMixin = ColorMixin & BackgroundMixin & HighlightMixin;
interface ColorFromPropsOptions {
  defaultBackground?: Color;
  onEvent?: boolean;
}

export const colorFromPropsMixin = (
  props: ColorFromPropsMixin,
  { defaultBackground = 'white', onEvent = undefined }: ColorFromPropsOptions | undefined = {}
) => {
  if (props.background) return softColorMixin(props, onEvent);
  if (props.color) return solidColorMixin(props, onEvent);
  if (props.highlight) return fadedColorMixin(props, onEvent);

  return softColorMixin({ ...props, background: defaultBackground }, onEvent);
};
