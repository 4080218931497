import styled from 'styled-components';

export const MenuBarItem = styled.li.attrs(() => ({
  role: 'none',
}))`
  height: ${props => props.theme.components.header.height};
  list-style-type: none;
  position: relative;

  display: flex;
  align-items: center;

  a {
    text-decoration: none;
  }
`;
