import { getContrast } from 'polished';

import { theme } from 'styled/theme';

const defaultWhiteHex = theme.colors.white;
const defaultBlackHex = theme.colors.black;

const ratios = {
  AAA: 7,
  AA: 4.5,
  A: 2, // has no ratio requirement technically, but a ratio of 1 is no contrast
};

export interface GetReadableColorOptions {
  hex: string;
  requiredContrast?: keyof typeof ratios | 'optimal';
  whiteHex?: string;
  blackHex?: string;
}

/**
 * Picks the color (black or white) that exceeds/meets the given contrast ratio
 * requirement, when compared with the given hex color. If neither do, it returns black.
 *
 * If using the 'optimal' option, it will return the color that results in the highest contrast.
 */
export const getReadableColor = ({
  hex,
  requiredContrast = 'AAA',
  blackHex = defaultBlackHex,
  whiteHex = defaultWhiteHex,
}: GetReadableColorOptions): string => {
  const colorOptions = [whiteHex, blackHex];

  // Pick color option that gives the best contrast ratio
  if (requiredContrast === 'optimal') {
    const returnColor = colorOptions.reduce((max, current) => {
      const contrast = getContrast(current, hex);
      return contrast > getContrast(max, hex) ? current : max;
    }, whiteHex);

    if (getContrast(returnColor, hex) < ratios.AA) {
      // eslint-disable-next-line no-console
      console.warn('Optimal color does not meet contrast ratio of AA');
    }

    return returnColor;
  }

  /**
   * Pick color option that meets the given contrast ratio, if any.
   * Otherwise return black.
   */
  const returnColor = colorOptions.find(c => {
    const contrast = getContrast(c, hex);

    return contrast >= ratios[requiredContrast];
  });

  return returnColor || defaultBlackHex;
};
