import uniqBy from 'lodash/uniqBy';

import type { Locale, LocaleLang } from '@sharesight/gatsby-plugin-sharesight-localization';

interface BaseNode {
  contentfulId: string;
  localeLang: LocaleLang;
}

interface Params<T extends BaseNode> {
  /** Nodes must be related to each other, eg. they must all share the same `contentfulId`, but they will have different `localeLang`. */
  nodes: T[];
  defaultLocale: Locale;
  locale: Locale;
  debug: string;
}

/**
 * Find the locale and global page nodes given a grouped set of nodes.
 *
 * If your nodes includes entries with different `contentfulId`s, this will throw an error.
 * The intent for this is to take a list of ~1-6 nodes all with the same `contentfulId`, but different `localeLang`, and pull out the variants.
 */
export const findNodePages = <T extends BaseNode>({
  nodes,
  defaultLocale,
  locale,
  debug,
}: Params<T>): { localePage?: T; globalPage?: T; contentfulId?: string } => {
  if (!nodes.length) return {};

  /*
   * If the glob match comes back with multiple pages, we cannot continue.
   * Eg. a page with a match of `blog/*` and another with `blog*` would both match on `/blog/…`.
   */

  const uniqueContentfulPages = uniqBy(nodes, 'contentfulId');
  const contentfulPageId = uniqueContentfulPages[0].contentfulId;

  if (uniqueContentfulPages.length !== 1) {
    throw new Error(
      `Found ${uniqueContentfulPages.length} ${debug}.
      Found ids: [${uniqueContentfulPages.map(n => n.contentfulId).join(', ')}]`
    );
  }

  // It would be very odd if we found a page, but it was not the global page…
  const globalPage = nodes.find(node => node.localeLang === defaultLocale.lang);
  if (!globalPage) {
    throw new Error(`Did not find a Global ${debug} and id='${contentfulPageId}'.`);
  }

  const localePage = nodes.find(node => node.localeLang === locale.lang);

  return { localePage: localePage || globalPage, globalPage, contentfulId: contentfulPageId };
};
