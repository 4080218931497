import { unLocalizedPaths as defaultUnLocalizedPaths } from 'data/unLocalizedPaths';

import { normalizePath } from './normalizePath';

import type { UnLocalizedPath } from 'data/unLocalizedPaths';

interface ShouldLocalizePathOptions {
  path: string;
  unLocalizedPaths?: UnLocalizedPath[];
}

export const shouldLocalizePath = ({
  path,
  unLocalizedPaths = defaultUnLocalizedPaths,
}: ShouldLocalizePathOptions) => {
  const pathNormalized = normalizePath({ path });

  const matchesUnLocalizedPath = unLocalizedPaths.some(pathMatch => {
    if (typeof pathMatch === 'string') return normalizePath({ path: pathMatch }) === pathNormalized;
    if (pathMatch instanceof RegExp) return pathMatch.test(pathNormalized);
    return false;
  });

  return !matchesUnLocalizedPath;
};
