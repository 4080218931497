import styled, { useTheme } from 'styled-components';

import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import MobileSVG from 'images/sharesight.mobile.inline.svg';
import SVG from 'images/sharesight.inline.svg';

import { GatsbyLink } from 'components/Links';

import type { Theme } from 'styled/types';
import type { FC } from 'react';

// To ensure we don't get an extra whitespace below the icon, enlarging the header..
const LinkContainer = styled.div`
  font-size: 0;
`;

// To ensure we don't too much "clickable space" around the logo.
const StyledLink = styled(GatsbyLink)`
  display: inline-block;
`;

const StyledSvg = styled(SVG)`
  display: block;
  padding: ${props => props.theme.components.header.logoVerticalPadding} 0;
  height: auto;
`;

const StyledMobileSvg = styled(MobileSVG)`
  display: block;
  padding: ${props => props.theme.components.header.logoVerticalPadding} 0;
  height: auto;
`;

const getLogoDimensions = (theme: Theme, isMobile: boolean) => {
  /*
   * Button height should be the max
   * available height that the logo can take up.
   * default height of the logo, which is the same as a button's height
   */
  const height = theme.components.button.height;

  const ratio = isMobile ? 1 : 135 / 34; // svg width / height
  const width = `${parseFloat(height) * ratio}rem`;

  return { height, width };
};

export const SharesightLogo: FC<{}> = () => {
  const theme = useTheme();
  const isMobile = !!useMediaQuery(theme.breakpoints.down('sm'));
  const { height, width } = getLogoDimensions(theme, isMobile);

  return (
    <LinkContainer>
      <StyledLink to="/">
        {isMobile ? (
          <StyledMobileSvg width={width} height={height} role="img" />
        ) : (
          <StyledSvg width={width} height={height} role="img" />
        )}
      </StyledLink>
    </LinkContainer>
  );
};
