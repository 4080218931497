import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { normalizePath } from 'helpers/url';

import { findNodePages } from './findNodePages';
import { useSocialImage } from './useSocialImage';
import type {
  ContentfulCampaignGraphQLResponse,
  ContentfulCampaignPage,
} from 'types/campaign/campaign';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { ContentfulPage } from './types';

const query = graphql`
  query ContentfulCampaignPages {
    allContentfulCampaignPage {
      nodes {
        contentfulId: contentful_id
        localeLang: node_locale
        shouldAppendTitle: appendTitle
        ctaHeader
        ctaSubheader
        description
        displayAwardImages
        displayFeaturedInImages
        micetypeCta {
          textContent {
            raw
          }
        }
        campaignCode
        noIndex
        path
        pricingDiscountPercentage
        title
      }
    }
  }
`;

/** Get a ContentfulPage data object for a affiliate/campaign page. */
export const useCampaignPage = ({
  path,
  locale,
}: {
  path: string;
  locale: Locale;
}): ContentfulPage | undefined => {
  const { appendTitle, socialAppendTitle } = useContentfulLocale(locale);

  const { defaultLocale } = useLocalizationContext();
  const { allContentfulCampaignPage }: ContentfulCampaignGraphQLResponse = useStaticQuery(query);

  const socialImage = useSocialImage();

  const { localePage, globalPage } = useMemo(() => {
    const campaignPageNodes = allContentfulCampaignPage.nodes.filter(aff => {
      const CampaignPath = normalizePath({ path: aff.path });

      return normalizePath({ path }) === CampaignPath;
    });

    if (!campaignPageNodes) return {};

    return findNodePages<ContentfulCampaignPage>({
      nodes: campaignPageNodes,
      defaultLocale,
      locale,
      debug: `Contentful::CampaignPage for path='${path}'`,
    });
  }, [allContentfulCampaignPage, defaultLocale, locale, path]);

  if (!localePage || !globalPage) return undefined;

  let title = localePage.title;
  if (localePage.shouldAppendTitle) title += ` | ${appendTitle}`;

  let socialTitle = globalPage.title;
  if (globalPage.shouldAppendTitle) socialTitle += ` | ${socialAppendTitle}`;

  return {
    cards: [],
    accordionItems: [],
    sections: [],
    translations: {},
    campaignCode: localePage.campaignCode || globalPage.campaignCode,
    seoProps: {
      noIndex: localePage.noIndex,
      title,
      rawTitle: localePage.title, // passed around so we can use it for some labels or alt/title attributes
      description: localePage.description,
      socialTitle,
      socialDescription: globalPage.description,
      socialImage,
    },
  };
};
