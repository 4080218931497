import { normalizePath } from './normalizePath';

import type { ContentfulBlogPostRaw } from 'types/blog/blogPost';
import type { SimpleBlogPostNode } from 'types/blog/simpleBlogPost';

/**
 * This is a workaround until all the blog post urlSlugs are populated
 * returns e.g. `/blog/3-ways-sharesight-helps-investors-at-tax-time/`
 */

export const getBlogPostUrlSlug = (post: ContentfulBlogPostRaw | SimpleBlogPostNode) => {
  if (!post.urlSlug) {
    throw new Error(`Found a Blog Post with no URL Slug, see: ${post.contentfulId}`);
  }

  return normalizePath({ path: `/blog/${post.urlSlug}/` });
};
