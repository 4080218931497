import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { extendContentfulLocale } from './extendContentfulLocale';

import type { ContentfulLocale, ContentfulLocalesGraphQLResponse } from './types';

export const ContentfulLocalesQuery = graphql`
  query ContentfulLocales {
    allContentfulLocale {
      nodes {
        lang
        translations {
          internal {
            content
            mediaType
          }
        }
        priceIncludesTax
      }
    }
  }
`;

export const useContentfulLocales = (): ContentfulLocale[] => {
  const { defaultLocale, siteLocales } = useLocalizationContext();
  const { allContentfulLocale }: ContentfulLocalesGraphQLResponse =
    useStaticQuery(ContentfulLocalesQuery);

  return useMemo(
    () =>
      siteLocales.map(locale =>
        extendContentfulLocale({
          nodes: allContentfulLocale.nodes,
          defaultLocale,
          locale,
        })
      ),
    [allContentfulLocale.nodes, siteLocales, defaultLocale]
  );
};
