import { darken, lighten } from 'polished';
import { theme } from 'styled/theme';

export const buttonBackground = {
  positive: {
    noEvent: {
      primary: theme.colors.primary,
      secondary: theme.colors.transparent,
      tertiary: theme.colors.transparent,
      link: theme.colors.transparent,
    },
    hover: {
      primary: lighten(0.4, theme.colors.primary),
      secondary: theme.colors.black,
      tertiary: theme.colors.transparent,
      link: theme.colors.transparent,
    },
    active: {
      primary: darken(0.15, theme.colors.primary),
      secondary: lighten(0.6, theme.colors.navyBlue),
      tertiary: theme.colors.transparent,
      link: theme.colors.transparent,
    },
  },
  negative: {
    noEvent: {
      primary: lighten(0.1, theme.colors.primary),
      secondary: theme.colors.white,
      tertiary: theme.colors.transparent,
      link: theme.colors.transparent,
    },
    hover: {
      primary: lighten(0.4, theme.colors.primary),
      secondary: theme.colors.transparent,
      tertiary: theme.colors.transparent,
      link: theme.colors.transparent,
    },
    active: {
      primary: darken(0.1, theme.colors.primary),
      secondary: lighten(0.6, theme.colors.navyBlue),
      tertiary: theme.colors.transparent,
      link: theme.colors.transparent,
    },
  },
};

export const buttonTextColor = {
  positive: {
    noEvent: {
      primary: theme.colors.white,
      secondary: theme.colors.black,
      tertiary: theme.colors.black,
      link: theme.colors.black,
    },
    hover: {
      primary: theme.colors.white,
      secondary: theme.colors.white,
      tertiary: lighten(0.2, theme.colors.primary),
      link: lighten(0.2, theme.colors.primary),
    },
    active: {
      primary: theme.colors.white,
      secondary: theme.colors.white,
      tertiary: lighten(0.05, theme.colors.primary),
      link: lighten(0.05, theme.colors.primary),
    },
  },
  negative: {
    noEvent: {
      primary: theme.colors.white,
      secondary: theme.colors.black,
      tertiary: theme.colors.white,
      link: theme.colors.white,
    },
    hover: {
      primary: theme.colors.white,
      secondary: theme.colors.white,
      tertiary: lighten(0.3, theme.colors.primary),
      link: lighten(0.3, theme.colors.primary),
    },
    active: {
      primary: theme.colors.white,
      secondary: theme.colors.white,
      tertiary: lighten(0.45, theme.colors.primary),
      link: lighten(0.45, theme.colors.primary),
    },
  },
};

export const buttonBorder = {
  positive: {
    noEvent: {
      primary: 'none',
      secondary: `2px solid ${theme.colors.black}`,
      tertiary: 'none',
      link: 'none',
    },
    hover: {
      primary: 'none',
      secondary: `2px solid ${theme.colors.black}`,
      tertiary: 'none',
      link: 'none',
    },
    active: {
      primary: 'none',
      secondary: `2px solid ${lighten(0.15, theme.colors.grey)}`,
      tertiary: 'none',
      link: 'none',
    },
  },

  negative: {
    noEvent: {
      primary: 'none',
      secondary: `2px solid ${theme.colors.black}`,
      tertiary: 'none',
      link: 'none',
    },
    hover: {
      primary: 'none',
      secondary: `2px solid ${theme.colors.white}`,
      tertiary: 'none',
      link: 'none',
    },
    active: {
      primary: 'none',
      secondary: `2px solid ${lighten(0.15, theme.colors.grey)}`,
      tertiary: 'none',
      link: 'none',
    },
  },
};
