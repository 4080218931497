import { createRef, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useOnOutsideInteract } from 'hooks/useOnOutsideInteract';
import { useOnEscape } from 'hooks/useOnEscape';

import { Overlay as OverlayBackground } from './Overlay.styled';
import { Portal } from './Portal';
import { OverlayTitle } from './OverlayTitle.styled';
import { Container } from './Container.styled';
import { CloseButton } from './CloseButton.styled';

import type { ReactNode, FC } from 'react';

export interface OverlayProps {
  handleClose: () => void;
  title?: ReactNode;
  children: ReactNode;
  noBottomPadding?: boolean;
}

export const Overlay: FC<OverlayProps> = ({
  children,
  handleClose,
  title,
  noBottomPadding = false,
}) => {
  const ref = createRef<HTMLDivElement>();

  useOnEscape(handleClose);
  useOnOutsideInteract(ref, handleClose);

  const id = useMemo(() => uuidv4(), []);

  return (
    <Portal>
      <OverlayBackground>
        <Container
          noBottomPadding={noBottomPadding}
          ref={ref}
          role="dialog"
          aria-labelledby={`dialog-title-${id}`}
          aria-describedby={`dialog-deesc-${id}`}
        >
          <CloseButton handleClose={handleClose} />

          {title && <OverlayTitle id={`dialog-ddesc-${id}`}>{title}</OverlayTitle>}

          <div id={`dialog-desc-${id}`}>{children}</div>
        </Container>
      </OverlayBackground>
    </Portal>
  );
};
