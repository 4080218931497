import styled from 'styled-components';
import { CardsContainer } from './CardsContainer.styled';

export const ContentCardsContainer = styled(CardsContainer)`
  align-items: flex-start;
  justify-content: flex-start;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;
