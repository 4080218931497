import { GatsbyLink } from 'components/Links';
import { MenuBarItem } from './MenuBarItem.styled';

import type { FC } from 'react';

interface MenuTargetProps {
  label: string;
  to: string;
  title?: string;
}

export const MenuTarget: FC<MenuTargetProps> = ({ label, to, title }: MenuTargetProps) => (
  <MenuBarItem>
    {/* usecontentful page called here */}
    <GatsbyLink
      asButton
      appearance="link"
      noUnderline // TODO: make this work
      role="menuitem"
      tabIndex={0}
      title={title}
      to={to}
    >
      {label}
    </GatsbyLink>
  </MenuBarItem>
);
