import { BaseButton } from './BaseButton';
import { StyledCaretDown, StyledCaretUp } from './DropdownButton.styled';
import type { HTMLAttributes, FC } from 'react';

interface DropdownButtonProps extends Pick<HTMLAttributes<HTMLButtonElement>, 'onClick'> {
  showDropdown: boolean;
}

export const DropdownButton: FC<DropdownButtonProps> = ({ showDropdown, onClick }) => (
  <BaseButton onClick={onClick} appearance="secondary">
    {showDropdown ? 'Hide topics' : 'Topics'}
    {showDropdown ? <StyledCaretUp weight="bold" /> : <StyledCaretDown weight="bold" />}
  </BaseButton>
);
