import { useMemo } from 'react';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { isProPath } from 'helpers/url';

export const useProPricing = () => {
  const { basePath } = useLocalizationContext();

  const isPro = useMemo(() => isProPath({ basePath }), [basePath]);

  /*
   * For pro views, we scroll down to the pricing table.
   * Otherwise, we link off to the pricing page.
   */
  return {
    title: isPro ? 'Scroll to see pricing' : undefined,
    path: isPro ? `${basePath}#pricing` : '/pricing',
  };
};
