import { useMemo } from 'react';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { getHelpUrl } from 'helpers/url';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useSiteMetadata } from 'hooks/useSiteMetadata';

import { getHumanReadable } from 'helpers/getHumanReadable';
import { FlexibleLinkComponent } from './FlexibleLinkComponent';

import type { HTMLAttributes, FC } from 'react';
import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';

export interface HelpLinkProps extends Omit<HTMLAttributes<HTMLAnchorElement>, 'color'> {
  toLocale?: Locale;
  to: string;
  asButton?: boolean;
}

export const HelpLink: FC<HelpLinkProps> = ({
  asButton,
  toLocale = undefined,
  to,
  title,
  children = null,
  ...props
}) => {
  const { helpUrl } = useSiteMetadata();
  const { currentLocale, defaultLocale } = useLocalizationContext();
  const locale = toLocale || currentLocale;

  const { appendTitle } = useContentfulLocale(locale);

  const link = getHelpUrl({ path: to, locale, defaultLocale, helpUrl });

  // const defaultTitle = to === '/' ? 'Help Centre' : getHumanReadable(to);

  const defaultTitle = useMemo(() => {
    if (to === '/' || to.includes(helpUrl)) {
      return 'Help Centre';
    }
    return getHumanReadable(to);
  }, [helpUrl, to]);

  const linkTitle = `${title || defaultTitle} | ${appendTitle} Help`;

  return (
    <FlexibleLinkComponent
      openInNewTab
      to={link}
      hrefLang={locale.lang}
      title={linkTitle}
      {...props}
      asButton={asButton}
    >
      {children}
    </FlexibleLinkComponent>
  );
};
