import { capitalize } from './capitalize';

export function getHumanReadable(str: string) {
  const transformedStr = str
    .replace(/[\-]+/g, ' ') // replace hyphens with spaces
    .replace(/[\_]+/g, ' ') // replace underscores with spaces
    .replace(/\//g, ''); // remove '/'

  return capitalize(transformedStr); // Capitalize the first word
}
