import { normalizeSiteUrl } from './normalizeSiteUrl';
import { normalizePath } from './normalizePath';

interface GetUrlOptions {
  siteUrl: string;
  path: string;
}

export function getUrl({ siteUrl, path }: GetUrlOptions) {
  return normalizeSiteUrl(siteUrl) + normalizePath({ path, siteUrl });
}
