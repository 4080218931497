import styled, { css } from 'styled-components';

import { removeLastChildMarginBottomMixin } from 'styled/helpers/removeLastChildMarginBottom';

import type { ReactNode, FC } from 'react';

export interface SectionProps {
  span?: number;
  children?: ReactNode;
  className?: string;
  center?: boolean;
}

const Container = styled.div<SectionProps>`
  ${removeLastChildMarginBottomMixin}

  ${({ center }) =>
    center &&
    css`
      margin: 0 auto;
      text-align: center;
    `}

  ${({ span }) =>
    span !== undefined &&
    css`
      grid-column-start: span ${span};

      ${({ theme }) => theme.breakpoints.down('md')} {
        grid-column-start: unset;
      }
    `}
`;

export const Section: FC<SectionProps> = ({ className, span = 2, center, children }) => (
  <Container span={span} className={className} center={center}>
    {children}
  </Container>
);
