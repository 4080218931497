import { Helmet } from 'react-helmet';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useMemo } from 'react';
import { useSiteMetadata } from 'hooks/useSiteMetadata';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { buildMetaTags } from './buildMetaTags';
import { buildLinks } from './buildLinks';
import { buildSchema } from './buildSchema';

import { buildBlogSchema } from './buildBlogSchema';
import type { FC } from 'react';

import type { MetaProps, SeoProps } from './types';

export const Seo: FC<SeoProps> = ({
  prevPath,
  nextPath,
  noIndex,
  socialImage,
  title,
  socialTitle,
  description,
  socialDescription,
  blogPost,
}: SeoProps) => {
  const { currentLocale, availableLocales, currentPath, basePath } = useLocalizationContext();
  const { seoProps } = useContentfulPage();
  const { siteUrl } = useSiteMetadata();

  const metaTags = useMemo(() => {
    const mergedMetas: MetaProps = {
      noIndex: noIndex || seoProps.noIndex,
      title: title || seoProps.title,
      socialTitle: socialTitle || seoProps.socialTitle,
      description: description || seoProps.description,
      socialDescription: socialDescription || seoProps.socialDescription,
      socialImage: socialImage || seoProps.socialImage,
    };

    return buildMetaTags(mergedMetas, {
      basePath,
      siteUrl,
      currentLocale,
      availableLocales,
    });
  }, [
    availableLocales,
    basePath,
    currentLocale,
    seoProps,
    siteUrl,
    title,
    socialTitle,
    socialDescription,
    description,
    noIndex,
    socialImage,
  ]);

  const links = useMemo(
    () =>
      buildLinks({
        currentPath,
        siteUrl,
        prevPath,
        nextPath,
      }),
    [currentPath, siteUrl, prevPath, nextPath]
  );

  const schemas = useMemo(() => {
    const schemasContent = buildSchema({ siteUrl, basePath, currentLocale });

    if (schemasContent.length === 0) return null;

    return schemasContent.map(schema => (
      <script type="application/ld+json" key={schema.key}>{`${schema.json}`}</script>
    ));
  }, [basePath, currentLocale, siteUrl]);

  const blogSchema = useMemo(() => {
    if (!blogPost) return null;
    const blogSchemaContent = buildBlogSchema({ siteUrl, basePath, blogPost });

    return (
      <script
        type="application/ld+json"
        key={blogSchemaContent.key}
      >{`${blogSchemaContent.json}`}</script>
    );
  }, [basePath, blogPost, siteUrl]);

  return (
    <Helmet
      htmlAttributes={{ lang: currentLocale.lang }}
      title={title || seoProps.title}
      meta={metaTags}
      link={links}
    >
      {schemas}
      {blogSchema}
    </Helmet>
  );
};
