import { CaretDown, CaretUp } from 'phosphor-react';
import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const StyledCaretDown = styled(CaretDown)`
  margin-left: ${getDimension('size1')};
`;

export const StyledCaretUp = styled(CaretUp)`
  margin-left: ${getDimension('size1')};
`;
