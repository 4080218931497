import { validateDomain } from 'components/RichText/validateDomain';
import { FlexibleLinkComponent } from './FlexibleLinkComponent';

import type { FC } from 'react';
import type { FlexibleLinkProps } from './FlexibleLinkComponent';

export type ExternalLinkProps = FlexibleLinkProps;

export const ExternalLink: FC<ExternalLinkProps> = ({
  asButton,
  appearance,
  color,
  textColor,
  borderColor,
  showArrowIcon,
  iconDirection,
  disabled,
  noUnderline,
  fullWidth,
  ref,
  containerBackground,
  to,
  title,
  openInNewTab = true,
  children,
}) => {
  const receivedErrorText = `Received '${to}' for link '${children}'.`;

  const isValid = validateDomain(to, receivedErrorText); // NOTE: This will throw an error if it fails.

  // NOTE: isValid should always be true or throw an error, this is just to protect against a `validateDomain` change.
  if (!isValid) {
    throw new Error(`Static-www domain validation failed. ${receivedErrorText}`);
  }

  return (
    <FlexibleLinkComponent
      openInNewTab={openInNewTab}
      to={to}
      title={title}
      asButton={asButton}
      appearance={appearance}
      containerBackground={containerBackground}
      color={color}
      textColor={textColor}
      borderColor={borderColor}
      showArrowIcon={showArrowIcon}
      iconDirection={iconDirection}
      disabled={disabled}
      noUnderline={noUnderline}
      fullWidth={fullWidth}
      ref={ref}
    >
      {children}
    </FlexibleLinkComponent>
  );
};
