import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useMemo } from 'react';
import { getLocalizedPath } from 'helpers/url';

import { capitalize } from 'helpers/capitalize';
import { FlexibleLinkComponent } from './FlexibleLinkComponent';
import type { Appearance } from 'components/Button/types';
import type { Color } from 'styled/types';

import type { FC } from 'react';

import type { FlexibleLinkProps } from './FlexibleLinkComponent';
import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';

export interface PartnerLinkProps extends Omit<FlexibleLinkProps, 'openInNewTab'> {
  toLocale?: Locale;
  title?: string;
  borderColor?: Color;
  appearance?: Appearance;
}

/**
 * NOTE: this is essentially the GatsbyLink without the useGatsbyPage hook
 * this is an interim solution until we have configuration for pages in the partners space
 * TODO Vimaly ticket: https://vimaly.com/#j8mqm/t/www-gatsby_Partner_links_Blog_links/iiyAO6iiFO9OskjK6
 */

export const PartnerLink: FC<PartnerLinkProps> = ({
  asButton = false,
  children = null,
  color = undefined,
  disabled = false,
  noUnderline = false,
  textColor = undefined,
  appearance = 'secondary',
  title,
  to,
  toLocale = undefined,
  borderColor = undefined,
  ...props
}) => {
  const { currentLocale, availableLocales } = useLocalizationContext();
  const locale = toLocale || currentLocale;

  const fallbackTitle = `${capitalize(to.replace('-', ' '))} | Sharesight`;

  const shouldLocalize = useMemo(
    () => !availableLocales.some(loc => to.includes(loc.id)),
    [availableLocales, to]
  );

  const link = shouldLocalize ? getLocalizedPath({ path: to, locale }) : to;

  return (
    <FlexibleLinkComponent
      {...props}
      openInNewTab={false}
      to={link}
      title={title || fallbackTitle}
      textColor={textColor}
      color={color}
      hrefLang={locale.lang}
      disabled={disabled}
      asButton={asButton}
      noUnderline={noUnderline}
      borderColor={borderColor}
      appearance={appearance}
    >
      {children || title}
    </FlexibleLinkComponent>
  );
};
