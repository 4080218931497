import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useSiteMetadata } from 'hooks/useSiteMetadata';
import { normalizeAndTrimPath } from 'helpers/url';

const isRemoteHost = (url: string, siteUrl: string) => {
  try {
    const siteUrlSplit = new URL(siteUrl);
    const toUrlSplit = new URL(url, url.includes('://') ? undefined : siteUrl); // assume relative paths are on our siteUrl

    if (siteUrlSplit.origin === toUrlSplit.origin) return false;
  } catch (e) {
    // if any links just fail, let's treat them as remote links…
  }

  return true;
};

const GatsbyPagePathsQuery = graphql`
  query GatsbyPagePaths {
    allSitePage {
      nodes {
        path
      }
    }
  }
`;

interface GatsbyPagePathsGraphQLResponse {
  allSitePage: {
    nodes: {
      path: string;
    }[];
  };
}

/**
 * WARNING: Unfortunately, some of our "local paths" come from another property—not gatsby.
 * Any page that isn't in Gatsby should be treated as remote. * All remotes should use an `<a />` tag rather than a `<Link />` tag as a `<Link />` uses local routing.
 * Otherwise now we get spammed with preloading of `/faq/page-data.json` type of stuff, which we'll never have built.
 *
 * Similar context: https://github.com/gatsbyjs/gatsby/issues/16097
 *
 * This is a "temporary" workaround while we have two properties at the same domain (Middleman and Gatsby).
 *
 * Once everything is in Gatsby, we could get rid of this `GatsbyPagePathsQuery` logic.
 */

export const useIsRemotePath = (path: string): boolean => {
  const { siteUrl } = useSiteMetadata();
  const { allSitePage }: GatsbyPagePathsGraphQLResponse = useStaticQuery(GatsbyPagePathsQuery);

  const isRemoteUrl = useMemo(() => isRemoteHost(path, siteUrl), [path, siteUrl]);
  const gatsbyPaths = useMemo(() => allSitePage.nodes.map(node => node.path), [allSitePage]);

  // If the url host does not match, it's remote.
  if (isRemoteUrl) return true;

  // Iterate over all "gatsby" paths to determine if it's a Gatsby Link.
  if (
    gatsbyPaths.some(
      p => normalizeAndTrimPath({ path: p }) === normalizeAndTrimPath({ path, siteUrl })
    )
  ) {
    return false;
  }

  // Everything else is remote, even `/faq` when it's not in the `gatsbyPaths` is remote.
  return true;
};
