interface RawTranslationsInterface {
  id: string;
  internal: {
    content: string;
    mediaType: 'application/json';
  };
}

/**
 * These translations live in Gatsby, eg. under the "Locale" content type.
 *
 * The types like freeUsers: '300,000' or country: 'Global' | 'Canada'|  etc..  are just for reference, they are not guaranteed; technically Contentful could change at any time and this could become like freeUsers: 300000 (change to a number) or another country could be added`.
 */

export type RawTranslations = RawTranslationsInterface | undefined;
export interface Translations {
  DRIP?: 'DRIP' | 'DRP';
  stock?: 'stock' | 'share';
  country?: 'Global' | 'Canada' | 'New Zealand' | 'United States' | 'United Kingdom' | 'Australia';
  freeUsers?: string;
  mutualFund?: 'mutual fund' | 'managed fund';
  currencyCode?: 'USD' | 'NZD' | 'GBP' | 'AUD' | 'CAD';
  currencySymbol?: '$' | '£';
  /** e.g. `Canadian investors', 'New Zealand investors' */
  countryInvestors?: string;
  supportedMarkets?: number;
  trackedInvestments?: string;
  supportedBrokers?: number;
  supportedCurrencies?: number;
  supportedCryptoCurrencies?: number;
  /** e.g. 'Built for US investors', 'Built for UK investors' */
  builtForInvestors?: string;
  /** e.g. "Investors in over 100 countries use Sharesight to effortlessly aggregate all the info they need to file their tax returns." */
  investorsJustLikeYou?: string;
  pricingNote?: string;
  ctaNoticeHeader?: string;
  ctaNoticeNote?: string;
  [key: string]: string | number | boolean | null | undefined;
}

const validateAndParseTranslations = (translations: RawTranslations, errorContext: string) => {
  if (!translations) return false;
  if (!translations.internal.content) return false;
  if (translations.internal.mediaType === 'application/json') {
    return JSON.parse(translations.internal.content);
  }

  throw new Error(
    `Found a bad translation, received: ${translations.internal.mediaType}. (context: ${errorContext})`
  );
};

export function mergeTranslations(
  globalTranslations: RawTranslations,
  localeTranslations: RawTranslations,
  errorContext: string = 'unknown'
): Translations {
  const global = validateAndParseTranslations(globalTranslations, errorContext);
  const locale = validateAndParseTranslations(localeTranslations, errorContext);

  if (!global && !locale) return {};

  if (!global) return locale;
  if (!locale) return global;

  return { ...global, ...locale };
}
