/**
 * e.g. '/blog/hello-world/' returns 'hello-world'
 */
export const getBlogUrlSlug = (path: string) => {
  if (!/^\/blog\/[A-z0-9]+/.test(path)) {
    throw new Error(`Invalid blog path: ${path}`);
  }

  const slug = path.replace(/^\/blog\//, '');
  return slug.replace(/\//g, '');
};
