import styled from 'styled-components';

import type { ReactNode, FC } from 'react';

interface StyledSocialLinkProps {
  size: 'xs' | 'sm';
  translucent: boolean;
}

const StyledSocialLink = styled.a<StyledSocialLinkProps>`
  display: flex;
  max-width: fit-content;
  justify-content: center;
  align-items: center;

  opacity: ${props => (props.translucent ? '0.7' : '1')};
  transition: 250ms opacity;
  will-change: opacity;

  svg,
  img {
    /** Arbitrary size on Social Images */
    max-height: ${props => props.theme.components.socialLink.size[props.size]};
  }

  &:hover,
  &:focus,
  &:focus-within {
    opacity: ${props => (props.translucent ? '1' : '0.7')};
  }
`;

interface SocialLinkProps {
  /** Should be an image */
  children: ReactNode;
  href: string;
  title: string;
  /** Size of the image - defaults to 'sm' */
  size?: StyledSocialLinkProps['size'];
  /** if true, image has some transparency */
  translucent?: StyledSocialLinkProps['translucent'];
  className?: string;
}

/**
 * This is a link to a social media site, where an image is used as a link.
 * These always open in a new tab.
 */
export const SocialLink: FC<SocialLinkProps> = ({
  children,
  href,
  title,
  size = 'sm',
  translucent = false,
  className,
}) => (
  <StyledSocialLink
    className={className}
    href={href}
    title={title}
    size={size}
    translucent={translucent}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </StyledSocialLink>
);
