import { useMemo } from 'react';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useContentfulLocales } from 'hooks/useContentfulLocales';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { ContentfulLocale } from 'hooks/useContentfulLocales';

export function useContentfulLocale(locale: Locale | undefined = undefined): ContentfulLocale {
  const { currentLocale } = useLocalizationContext();
  const locales = useContentfulLocales();

  const findLocale = locale || currentLocale;
  const found = useMemo(() => locales.find(l => l.id === findLocale.id), [locales, findLocale]);

  if (!found) throw new Error(`Could not find locale: ${findLocale.id}`);

  return found;
}
