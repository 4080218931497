import { useCallback, useEffect } from 'react';

const escapeKeys = ['esc', 'escape'];

export const useOnEscape = (callback: (event: KeyboardEvent) => void) => {
  const handleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (escapeKeys.includes(event.key.toLowerCase())) callback(event);
    },
    [callback]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false);

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape]);
};
