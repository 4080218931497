export const UTM_COOKIE_NAME = 'utm_parameters';

const GCLID = 'gclid';
const UTM_CAMPAIGN = 'utm_campaign';
const UTM_CONTENT = 'utm_content';
const UTM_MEDIUM = 'utm_medium';
const UTM_SOURCE = 'utm_source';
const UTM_TERM = 'utm_term';
const CAMPAIGN_ID = 'campaign_id';
const DEVICE = 'device';
const GEO_LOCATION = 'geo_location';
const PLACEMENT = 'placement';

export const utmParameterKeys = [
  GCLID,
  UTM_CAMPAIGN,
  UTM_CONTENT,
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_TERM,
  CAMPAIGN_ID,
  DEVICE,
  GEO_LOCATION,
  PLACEMENT,
];
