import styled, { css } from 'styled-components';

import { Container as BaseContainer } from 'components/Container/Container.styled';
import { getDimension } from 'styled/helpers/getDimension';

interface ContainerProps {
  noBottomPadding?: boolean;
}

export const Container = styled(BaseContainer).attrs(() => ({
  background: 'white',
  padding: 0,
  manageLayout: false,
}))<ContainerProps>`
  margin: 0 auto;
  min-height: 25vh;
  width: 100%;
  max-width: 800px;
  min-width: 600px;
  padding: ${getDimension('size10')};
  overflow: auto;
  max-height: 100%;
  border-radius: 30px;

  ${({ noBottomPadding }) =>
    noBottomPadding &&
    css`
      padding-bottom: 0;
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-width: unset;
    min-height: 100vh;
    padding: ${getDimension('size4')};
    border-radius: 0;

    ${({ noBottomPadding }) =>
      noBottomPadding &&
      css`
        padding-bottom: 0;
      `}
  }
`;
