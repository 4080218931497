import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';

import { isBrowser } from 'helpers/isBrowser';
import { encodeBase64 } from 'helpers/encodeBase64';
import { UTM_COOKIE_NAME, utmParameterKeys } from './constants';

const expirationTime = 2592000 * 1000; // One month in milliseconds
const maxAge = 30 * 24 * 60 * 60; // 30 days (in seconds)

export const UTMCookie = () => {
  const [_cookie, setCookie] = useCookies([UTM_COOKIE_NAME]);

  const utmParameters = useMemo(() => {
    if (!isBrowser) return {};

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return utmParameterKeys.reduce<{ [key: string]: string }>((acc, utmParameter) => {
      const utmParameterValue = urlParams.get(utmParameter);
      if (utmParameterValue) {
        return { ...acc, [utmParameter]: utmParameterValue };
      }
      return acc;
    }, {});
  }, []);

  useEffect(() => {
    const date = new Date();
    const dateTimeNow = date.getTime();
    date.setTime(dateTimeNow + expirationTime); // Sets expiration time (Time now + one month)

    const encodedUtmParameters = encodeBase64(utmParameters);
    if (Object.keys(utmParameters).length > 0) {
      setCookie(UTM_COOKIE_NAME, encodedUtmParameters, {
        path: '/',
        domain: '.sharesight.com',
        expires: date,
        maxAge,
        secure: true,
        sameSite: 'lax',
      });
    }
  }, [setCookie, utmParameters]);

  return null;
};
